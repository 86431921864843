import React, { useEffect, useState } from 'react';
import './Pricing.css';
import { tiers } from './data/priceData';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { auth, db } from '../auth/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

export default function Pricing() {
    const [currentTier, setCurrentTier] = useState();
    const [subscriptionID, setSubscriptionID] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            const docRef = doc(db, "customers", user.uid);
            const rawData = await getDoc(docRef);
            const data = rawData.data();

            setCurrentTier(data.subscriptionType);
            setSubscriptionID(data.subscriptionId);
        })
    })

    return(
        <div className='mainPricing'>
            <h2 className='pricingTop'>
                Pricing
            </h2>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    marginTop: 50,
                    width: '60%',
                    marginLeft: '18%',
                }}
            >
                {tiers.map((tier) => {
                    return(
                        <Card tier={tier} key={tier.id} nav={navigate} divBacking={tier.divBacking} currentTier={currentTier} subscription_id={subscriptionID} />
                    )
                })}
            </div>
        </div>
    )
}

const Card = props => {
    let current;

    if(props.currentTier==props.tier.type){
        props.tier.avail = "Subscribed";
        current = true;
    }
    

    return(
        <div
            style={{
                width: 375,
            }}
        >
            <div id={props.tier.divBacking}
                style={{
                    height: 500,
                    width: 350,
                    border: '1px solid lightgrey',
                    backgroundColor: '#333333',
                    color: 'white',
                    borderRadius: 6,
                    paddingTop: 10,
                    textAlign: 'center',
                    margin: 40,
                }}
            >
                <h1
                    style={{
                        borderBottom: '1px solid white',
                        width: '80%',
                        position: 'relative',
                        left: '10%',
                        paddingBottom: 25,
                        fontWeight: 300,
                        fontFamily: 'Inter',
                    }}
                >
                    {props.tier.title}
                </h1>
                <div
                    style={{
                        position: 'relative',
                        top: 5,
                        left: '10%',
                        borderBottom: '1px solid white',
                        width: '80%'
                    }}
                >
                    <h2 style={{
                        fontSize: 35,
                        fontWeight: 500,
                        fontFamily: 'Inter Tight',
                        fontColor: props.tier.color,
                    }}>
                        {props.tier.requests}
                    </h2>
                    <h4 
                        style={{
                            position: 'relative',
                            top: -25,
                            fontWeight: 300,
                            fontFamily: 'Inter',
                        }}
                    >
                        Requests per Month
                    </h4>
                </div>
                <div
                    style={{
                        position: 'relative',
                        left: '10%',
                        borderBottom: '1px solid white',
                        width: '80%',
                    }}
                >
                    <h4
                        style={{
                            fontWeight: 300,
                            fontFamily: 'Inter',
                        }}
                    >
                        {props.tier.description}
                    </h4>
                </div>
                <h2
                    style={{
                        fontSize: 30,
                        fontWeight: 500,
                        fontFamily: 'Inter Tight',
                    }}
                >
                    {props.tier.price}
                </h2>
                <h5
                    style={{
                        position: 'relative',
                        top: -28,
                        fontWeight: 300,
                        fontFamily: 'Inter',
                    }}
                >
                    Monthly
                </h5>
                { current ? (
                    <>
                        <button
                            id="prodCurrent"
                            style={{
                                fontWeight: 500,
                                fontFamily: 'Inter Tight',
                                backgroundColor: '#333333',
                                color: 'white',
                            }}
                        >
                            {props.tier.avail}
                        </button>
                    </>
                ) : (
                    <button 
                        className="pricingDashButton"
                        onClick={() => {
                            props.nav("/dashboard/upgrade", {
                                params: {
                                    type: props.tier.type,
                                }
                            });
                            props.nav({
                                pathname: "/dashboard/upgrade",
                                search: createSearchParams({
                                    type: props.tier.type,
                                    subscription_id: props.subscription_id
                                }).toString()
                            })
                        }}
                        style={{
                            cursor: 'pointer',
                            fontWeight: 500,
                            fontFamily: 'Inter Tight',
                        }}
                    >
                        {props.tier.avail}
                    </button>
                )}
                
            </div>
        </div>
    )
}; 