import * as React from 'react';
import BarNav from './BarComponent';
import BottomLinks from './BottomLinks';
import {useNavigate} from 'react-router-dom'
import { tiers } from './data/priceData';
import { Helmet } from 'react-helmet';
import './PricingFront.css';


export default function PricingFront() {
    const navigate = useNavigate();


    return(
        <div className='pricingMain' style={{maxWidth: '1920px', margin: 'auto'}}>
            <Helmet>
                <html lang="en" />
                <title>Pricing</title>
                <link rel="canonical" href="http://arrayassistant.ai/pricing" />
                <meta name="description" content="Learn more about pricing and find the package that's right for you." />
                <meta name="keywords" content="excel, microsoft excel, ai for excel, ai for spreadsheets, ai tools" />
            </Helmet>
            <div className='pricingFrontContainer' >
                <div className='pricingTopText' >
                    <p id="pricingSubTitle" >
                        Great Plans. Flexible <span id="titleSpanPricing" >Pricing</span>.
                    </p>
                </div>
                <div className='pricingMainContent' >
                    {tiers.map((tier) => {
                        return(
                            <Card tier={tier} key={tier.id} nav={navigate}/>
                        )
                    })}
                </div>
                <div className='corpPricingInfo' >
                    <div id="corpTextContainer" >
                        <p id="corpHeader" >
                            Corporate <span id="titleSpanPricing" >Solutions</span>
                        </p>
                        <p id="corpDescription" >
                            Array Assistant can help save your business time and money by allowing your employees to work
                            more productively! For more information about corporate solutions and pricing, please contact us.
                        </p>
                    </div>
                    <div id="corpButtonContainer" >
                        <button id="corpContact"
                            onClick={() => {
                                navigate('/contact')
                            }}
                        >
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
            <div
                className='infoSection'
                style={{
                    width: '102%',
                    height: 250,
                    marginLeft: '-1%',
                    marginTop: 100,
                    color: 'white',
                    backgroundColor: '#1F1F1F' 
                }}
            >
                <BottomLinks />
            </div>
            <BarNav />
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
        </div>
    )
}


    
const Card = props => {
    return(
        <div>
            <div className="prodContainer" >
                { props.tier.recommended ? (
                    <div className='recContainer'>
                        <p id="prodTitlePop" >
                            {props.tier.title}
                        </p>
                        <p id="recSpan" >
                            {props.tier.rec}
                        </p>
                    </div>
                ) : (
                    <>
                        <p className="prodTitle" >
                            {props.tier.title}
                        </p>
                    </>
                )}
                <div
                    style={{
                        marginTop: 40,
                        marginLeft: '10%',
                        width: '80%'
                    }}
                >
                    <h2 style={{
                        fontSize: 39,
                        fontWeight: 500,
                        fontFamily: 'Inter Tight',
                        fontColor: props.tier.color,
                    }}>
                        {props.tier.requests}
                    </h2>
                    <h4 
                        style={{
                            position: 'relative',
                            top: -30,
                            fontWeight: 300,
                            fontFamily: 'Inter',
                        }}
                    >
                        Requests per Month
                    </h4>
                </div>
                { props.tier.promotion ? (
                    <div>
                        <h2
                            style={{
                                fontSize: 30,
                                fontWeight: 500,
                                fontFamily: 'Inter Tight',
                                marginLeft: '10%',
                                marginTop: 30,
                            }}
                        >
                            Free <s style={{fontFamily: 'Inter, sans-serif', fontWeight: 300}}>{props.tier.price}</s><span className='monthlySpan'>/Monthly </span>
                        </h2>
                    </div>
                ) : (
                    <>
                        <h2
                            style={{
                                fontSize: 30,
                                fontWeight: 500,
                                fontFamily: 'Inter Tight',
                                marginLeft: '10%',
                                marginTop: 30,
                            }}
                        >
                            {props.tier.price}<span className='monthlySpan'>/Monthly </span>
                        </h2>
                    </>
                )}
                <button className="frontPurchase"
                    onClick={() => {
                        props.nav(props.tier.link);
                    }}
                >
                    {props.tier.avail}
                </button>
                { props.tier.promotion ? (
                    <>
                        <p
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 300,
                                fontSize: 12,
                                width: '100%',
                                textAlign: 'center',
                                marginTop: 2,
                            }}
                        >
                            
                        </p>
                    </>
                ) : (<></>)}
            </div>
        </div>
    )
};

