import Generator from '../media/FormulaGenerator.mp4';
import Explainer from '../media/FormulaExplainer.mp4';
import List from '../media/ListBuilder.mp4';
import Solver from '../media/ProblemSolver.mp4';
import Summarizer from '../media/Summarizer.mp4';
import Text from '../media/TextGeneration.mp4';
import Cleaner from '../media/DataCleaner.mp4';
import Extractor from '../media/DataExtractor.mp4';
import Trends from '../media/Trends.mp4';

export const features = [
    {
        "id": 1,
        "title": "The Formula Generator",
        "description": "With the Formula Generator tool, you can effortlessly speed up your workflow. There is no more need to come up with complex equations or formulas, just let Array Assistant do the heavy lifting!",
        "video": Generator,
        "side": true,
    },
    {
        "id": 2,
        "title": "The Trend Analyzer",
        "description": "Let the Trend Analysis tool help you discover new insights in your data. Simply select your data and let the AI go to work!",
        "video": Trends,
        "side": false,
    },
    {
        "id": 3,
        "title": "The List Builder",
        "description": "Save time with the List Builder tool. Given a prompt, this tool can create any kind of list that you need! Additionally, you can create lists from previously made lists, expanding functionality!",
        "video": List,
        "side": true,
    },
    {
        "id": 4,
        "title": "The Summarizer",
        "description": "Eliminate the task of writing summaries from your workflow with the Summarizer tool. Given a short topic or a large amount of text, the summarizer can produce a short and well written paragraph about anything!",
        "video": Summarizer,
        "side": false,
    },
    {
        "id": 5,
        "title": "The Problem Solver",
        "description": "If there is ever a problem you can't solve, just ask the Problem Solver tool. Depending on your needs, the tool will either give you a concise explanation or will explain the solution in steps.",
        "video": Solver,
        "side": true,
    },
    
    {
        "id": 6,
        "title": "The Text Generator",
        "description": "Allow the Text Generation tool to help you write faster and easier. Generate quick emails, long descriptions, or even customer response comments with just the click of a button!",
        "video": Text,
        "side": false,
    },
    {
        "id": 7,
        "title": "The Data Cleaner",
        "description": "Easily clean and format data with the Data Cleaner tool. Using this tool, the AI will help you quickly clean up any messy columns on your sheet, potentially saving you hours!",
        "video": Cleaner,
        "side": true,
    },
    {
        "id": 8,
        "title": "The Data Extractor",
        "description": "The Data Extractor tool is perfect for pulling names, addresses, or any other piece of data from a set. Its easy to use and perfect for cutting back on time spent on your spreadsheet.",
        "video": Extractor,
        "side": false,
    },
    {
        "id": 9,
        "title": "The Formula Explainer",
        "description": "The Formula Explainer tool can help explain any equation or formula you throw at it! Whether for work, school, or simply personal use, the Formula Explainer is the perfect tool to avoid road blocks!",
        "video": Explainer,
        "side": true,
    }
]