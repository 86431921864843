import React from 'react';
import './Landing.css';
import { TitleLink } from './StyledLink';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Logo2 from './Icons/Logo2.jpg';
import RightArrow from './Icons/RightArrow.png';
import HomeVideo from './media/NewHomeVid.mp4';
import badge from './media/PartnerBadge.png';

export default function Landing() {

    const navigate = useNavigate();

    return (
        <div className='mainLanding' >
            <Helmet>
                <html lang="en" />
                <title>Array Assistant AI</title>
                <meta name="description" content="Welcome to Array Assistant, the go-to AI tool for Microsoft Excel Spreadsheets! Click here to learn more." />
                <meta name="keywords" content="excel ai assistant, formula creator bot, ai formula creator, ai excel formula maker, chat gpt for excel, microsoft excel assistant" />
            </Helmet>
            <div className='logoDiv'
                    style={{
                        width: 310,
                    }}
                >
                    <img src={Logo2} height="46px" width="53px" alt="logo" onClick={() => {navigate("/")}}
                        style={{
                            position: 'absolute',
                            top: 20,
                            left: 33,
                            cursor: 'pointer',
                        }}
                    />
                    <TitleLink
                        to='/'
                        className='frontPage'
                        style={{
                            position: 'absolute',
                            left: 98,
                            top: 5,
                            fontFamily: 'Inter',
                        }}
                    >
                        <h1
                            style={{
                                fontWeight: '500',
                                letterSpacing: 0,
                                color: '#09CB62',
                                fontFamily: 'Inter Tight, sans-serif'
                            }}
                        >
                            Array<span style={{color: 'black'}}>Assistant</span>
                        </h1>
                    </TitleLink>
            </div>
            <div className='backgroundDiv'>
            </div>
            <div className='mainFlowDiv'>
                <div className='textDiv'>
                    <p id='mainLandingTitle'>
                        Unlock your Excel <span id="titleSpanLanding" >Superpowers</span> with Array Assistant's AI
                    </p>
                    <p id="mainLandingSubTitle">
                        Dive deeper into data, discover insights, and enhance productivity effortlessly. With tools including:
                    </p>
                    <ul id="featuresLandingList">
                        <li>Data Extraction</li>
                        <li>Trend Analysis</li>
                        <li>Formula Generation</li>
                        <li>And More...</li>
                    </ul>
                    <p id="mainLandingSubTitle">
                        Save hours a day starting at just $4.99 monthly!
                    </p>
                    <div className='buttonDiv'>
                        <button
                            id="signUpTodayLanding"
                            onClick={() => {
                                navigate("/createAccount");
                            }}
                        >
                            <h5
                                style={{
                                    position: 'relative',
                                    top: -18,
                                    fontSize: 20,
                                    marginRight: 7,
                                    marginLeft: 8,
                                    color: 'black',
                                    fontFamily: 'Inter Tight, sans-serif',
                                    fontWeight: 500
                                }}
                            >
                                Try Two Weeks Free Today
                            </h5>
                            <img src={RightArrow} alt="icon" height="30" width="50" id="arrow"
                                style={{ 
                                    position: 'relative', 
                                    top: 16,
                                }}
                            />
                        </button>
                    </div>
                </div>
                <div className='mediaDiv'>
                    <div id="frontContentDiv"
                        style={{
                            height: 385,
                            width: '100%',
                            backgroundColor: 'black',
                            position: 'relative',
                            marginRight: '3%',
                            borderRadius: 15,
                        }}
                    >
                        <video src={HomeVideo} height="378px" width="100%" controls="controls" autoPlay={true} loop style={{borderRadius: 27}}/>
                    </div>
                    <div id="trustedDiv">
                        <img id="partnerBadge" src={badge} height="110" width="200" 
                            style={{
                                marginTop: 5,
                                marginLeft: 5,
                            }}
                        />
                    </div>
                </div>
            </div>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
        </div>
    )
    }
