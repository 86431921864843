import React, { useState, useCallback, useEffect } from 'react';
import './formulaGen.css';
import { auth } from '../auth/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';

export default function FormulaGen() {
    const [data, setData] = useState("");
    const [type, setType] = useState("Excel");
    const [input, setInput] = useState("");
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setUser(user.uid);
        } else {
          console.log("User is not signed in");
        }
      })
    }, [])

    const handleInputChange = useCallback((event) => {
      setInput(event.target.value);
    });
  
    const fetchCall = useCallback(async () => {
      setLoading(true);
      await axios.post("https://aiserver.herokuapp.com/gen", {
        type: type,
        input: input,
        user: user,
      })
        .then((response) => {
          let text = response.data.choices[0].text;
          let formattedText = text.split("\n\n").join("");
          setData(formattedText);
          setLoading(false);
        })
        .catch((err) => {
          console.log({ message: err.message })
          setLoading(false);
        });
    });

    const copyFunction = useCallback( async () => {
      navigator.clipboard.writeText(data);
    });
  
    return (
      <div className="formulaMain">
        <div className='mainContainer'>
            <div className='titleSection'>
              <h2 className='mainTitle'
                style={{
                  fontFamily: 'Inter'
                }}
              >
                  Formula Creator
              </h2>
            </div>
            <div className='buttonSection'>
              <h4 className='formatTitle'>
                  Is this a formula for excel or sheets?
              </h4>
              <button 
                  className="excelButton"
                  onClick={() => {
                    setType("Excel");
                  }}
                >
                  Excel
              </button>
              <button 
                  className="sheetsButton"
                  onClick={() => {
                    setType("Sheets");
                  }}
                >
                  Sheets
              </button>
            </div>
            <div className='inputSection'>
              <h4 className='inputLabel'>
                  What should your {type} formula do?
              </h4>
              <textarea 
                  type="text" 
                  placeholder="Explain your forumla here..." 
                  rows='4'
                  cols='50'
                  className='mainInput'
                  onChange={handleInputChange}
              />
            </div>
            <div className='submitButtonDiv'>
              <button 
                  className='submitGenButton'
                  onClick={() => {
                    fetchCall();
                  }}
                  >
                  { loading ? "Loading..." : "Generate" }
              </button>
            </div>
            <div className='outputSection'>
              <h4 className='outputLabel'>
                  Formula Output:
              </h4>
              <textarea 
                type="text" 
                placeholder='Input something above!'
                value={data}
                rows='2'
                cols='50'
                className='mainOutput'
                readOnly
              />
              <div>
                <button id="copyGenButton"
                  onClick={() => {
                    copyFunction()
                  }}
                >
                  Copy Formula
                </button>
              </div>
            </div>
        </div>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
        <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
      </div>
    );
}