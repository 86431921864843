export const tiers = [
    {
        "id": 1,
        "title": "Basic",
        "requests": "200",
        "description": "Try your first month of Basic for only $0.99!",
        "price": "$4.99",
        "link": "/createAccount",
        "checkout": "/personal",
        "avail": "Try Now!",
        "color": "white",
        "trial": false,
        "promotion": true,
        "recommended": true,
        "rec": "Popular",
    },
    {
        "id": 2,
        "title": "Pro",
        "requests": "1000",
        "description": "Perfect for any professional who uses spreadsheets!",
        "price": "$14.99",
        "link": "/createAccount",
        "checkout": "/professional",
        "avail": "Subscribe",
        "color": "#008FFF",
        "trial": false,
        "promotion": false,
        "recommended": true,
        "rec": "Best Value",
    },
    {
        "id": 3,
        "title": "Enterprise",
        "requests": "Unlimited",
        "description": "Increase Company Productivity Today!",
        "price": "$49.99",
        "link": "/createAccount",
        "checkout": "/enterprise",
        "avail": "Subscribe",
        "color": "white",
        "trial": false,
        "promotion": false,
        "recommended": false,
    }
]