import React, { useState, useCallback, useEffect } from 'react';
import './CreateAccount.css';
import { setDoc, doc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../auth/firebase.js';
import { useNavigate } from 'react-router-dom';
import { SwitchLink } from './StyledLink';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import SideFill from './media/cA4.jpg';
import Logo from './Icons/Logo2.jpg';


export default function CreateAccount() {
    const [passCreate, setPassCreate] = useState("");
    const [emailCreate, setEmailCreate] = useState("");
    const [name, setName] = useState("");
    const [errorCreateAccount, setErrorCreateAccount] = useState(false);
    const [agreement, setAgreement] = useState(false);
    const [agreementError, setAgreementError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState();
    const [codeBool, setCodeBool] = useState(false);

    const currentDate = new Date();
    const search = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    const wakeFunction = async () => {
        await axios.post("https://aiserver.herokuapp.com/wake")
            .catch((err) => {
                console.log({ message: err.message });
            })
    }

    useEffect( () => {
        const linkCode = search.get('code');
        if(linkCode){
            setCode(linkCode)
        }
        wakeFunction();
    }, [])

    const createName = useCallback((event) => {
        setName(event.target.value);
    })

    const createEmail = useCallback((event) => {
        setEmailCreate(event.target.value);
    });

    const createPass = useCallback((event) => {
        setPassCreate(event.target.value);
    });

    const signUp = (email, password, name) => {
        setErrorCreateAccount(false);
        setAgreementError(false);
        setLoading(true);

        if(!agreement){
            setAgreementError(true);
            setLoading(false);
            return;
        }

        createUserWithEmailAndPassword(auth, email, password)
            .then(async () => {
                try {
                    await axios.post("https://aiserver.herokuapp.com/createCustomer", {
                        email: auth.currentUser.email,
                        name: name,
                        user_id: auth.currentUser.uid,
                        account_type: null,
                    })
                        .then(async (data) => {
                            await setDoc(doc(db, "customers", auth.currentUser.uid), {
                                firstName: name,
                                uid: auth.currentUser.uid,
                                userEmail: auth.currentUser.email,
                                userSubscription: false,
                                customerId: data.data,
                                subscriptionId: "none",
                                monthlyLimit: false,
                                subscriptionType: "none",
                                firstTime: true,
                                lifetimeRequests: 0,
                                dateCreated: `${currentDate.toDateString()}`,
                                UserAgreements: {
                                    terms: true,
                                    privacy_policy: true
                                }
                              });
                            setErrorCreateAccount(false);
                            setLoading(false);
                        })
                        .catch((err) => {
                            console.log({ message: err.message });
                            setErrorCreateAccount(true);
                            setLoading(false);
                        });
                } catch (err) {
                    console.log({ message: err.message });
                    setErrorCreateAccount(true);
                    setLoading(false);
                }
            })
            .then(() => {
                if(code){
                    navigate(`/products?code=${code}`)
                } else {
                    navigate('/products')
                }
            })
            .catch((err) => {
                console.log({ message: err.message });
                setErrorCreateAccount(true);
                setLoading(false);
            });
    };

    return(
        <div >
            <Helmet>
                <html lang="en" />
                <title>Create Account</title>
                <meta name="description" content="Create a new account with Array Assistant." />
                <meta name="keywords" content="create account for array assistant, ai excel tool, machine learning tools, ai helper, artificial intelligence" />
            </Helmet>
                    <div id="accountCreationDiv">
                        <div id="headerContainer" >
                            <img src={Logo} alt="Array Assistant Logo" height="35px" width="35px" id="siteLogoCreate" onClick={() => {navigate("/")}} />
                            <h3 id="siteTitle" onClick={() => {navigate("/")}} style={{ fontFamily: 'Inter Tight, sans-serif', fontWeight: 300}}>
                                <span style={{color: '#09CB62'}}>Array</span>Assistant
                            </h3>
                        </div>
                        <div id='googleAuthDiv2'>
                            <h2 id="accountCreationHeader">
                                Create a New Account
                            </h2>
                            <div id="mainGroup" >
                                <div id="groupContainer" >
                                    <h4 id='nameCreate' >
                                        Name:
                                    </h4>
                                    <input type="text" id='name' onChange={createName} autoFocus='autofocus' placeholder='Full Name'/>
                                </div>
                                <div id="groupContainer" >
                                    <h4 id='emailTitle'>
                                        Email:
                                    </h4>
                                    <input type="text" id='email' onChange={createEmail} placeholder='Email address'/>
                                </div>
                                <div id="groupContainer" >
                                    <h4 id='passTitle'>
                                        Password:
                                    </h4>
                                    <input type="password" id='pass' onChange={createPass} placeholder='Password'/>
                                </div>
                                <div id="termsGroup" >
                                    <input type="checkbox" id="terms" onClick={() => {
                                        setAgreement(!agreement);
                                    }}/>
                                    <p> I agree to the <span id="termsSpan" onClick={() => {navigate("/legal")}}>Terms</span> and <span id="termsSpan" onClick={() => {navigate("/legal")}}>Privacy Policy</span> </p>
                                </div>
                                <div id="groupContainer" >
                                    <button id='buttonInCreate' 
                                        onClick={() => { 
                                            signUp(emailCreate, passCreate, name);
                                        }}
                                    >
                                        { loading ? "Loading..." : "Create Account" }
                                    </button>
                                </div>
                                <div
                                    style={{
                                        position: 'relative',
                                        left: '2%',
                                        display: 'flex',
                                        top: 20,
                                    }}
                                >
                                    <p id="preSwitch"> Already Have an Account?&nbsp; </p>
                                    <SwitchLink
                                        to="/signIn"
                                        style={{
                                            placeItems: 'center'
                                        }}
                                    >
                                        <p> Sign In </p>
                                    </SwitchLink>
                                </div>
                                { !errorCreateAccount ? (
                                    <>
                                        <h4 id="errorMessage"
                                            style={{
                                                display: 'none'
                                            }}
                                        >
                                            Error Signing In. Try Again.
                                        </h4>
                                    </>
                                ) : (
                                    <h4 id="errorMessage"
                                        style={{
                                            borderRadius: 5,
                                            backgroundColor: '#FF6B6B',
                                            color: 'white',
                                            width: '78%',
                                            position: 'relative',
                                            marginTop: 35,
                                            left: '-2%',
                                            padding: 5,
                                            fontWeight: 400
                                        }}
                                    >
                                        Error Signing Up. Try Again.
                                    </h4>
                                )}
                                { !agreementError ? (
                                    <>
                                        <h4 id="errorMessage"
                                            style={{
                                                display: 'none'
                                            }}
                                        >
                                            -
                                        </h4>
                                    </>
                                ) : (
                                    <h4 id="errorMessage"
                                        style={{
                                            borderRadius: 5,
                                            backgroundColor: '#FF6B6B',
                                            color: 'white',
                                            width: '78%',
                                            position: 'relative',
                                            marginTop: 35,
                                            left: '-2%',
                                            padding: 5,
                                            fontWeight: 400
                                        }}
                                    >
                                        Please Agree to the Terms and Privacy Policy.
                                    </h4>
                                )}
                            </div>
                        </div>
                        <div id='imgContainer' >
                            <img src={SideFill} alt="Create Account Side Fill Graphic" height="550" width="900" id="imgBig" />
                        </div>
                    </div>
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
        </div>
    )
}