import React, { useState, useCallback } from 'react';
import axios from 'axios';

export default function TestRoute() {
    const [input, setInput] = useState()
    const [job, setJob] = useState();
    const [output, setOutput] = useState();
    const [value, setValue] = useState();

    const iHandle = useCallback((e) => {
        setInput(e.target.value);
    });

    const testCallFunction = async () => {
        await axios.post("https://aiserver.herokuapp.com/job", {
                input: input,
                type: 2,
            })
                .then((data) => {
                    const checkInterval = setInterval(async () => {
                        await axios.get(`https://aiserver.herokuapp.com/job/${data.data.job}`)
                            .then((response) => {
                                console.log(response);
                                if(response.data.state == "completed"){
                                    setOutput("completed" + response.data.value.value.choices[0].text);
                                    clearInterval(checkInterval)
                                } else {
                                    console.log("loading");
                                }
                            })
                            .catch((err) => { console.log({ message: err.message }) });
                    }, 5000)
                })
                .catch((err) => { console.log({ message: err.message }) });
    }

    return (
        <div className='pdfMain' >
            <input type="text" onChange={(text) => {iHandle(text);}} />
            <button
                onClick={() => {
                    testCallFunction();
                }}
            >
                Click to Start a Job
            </button>
            <p>
                {output}
            </p>
        </div>
    )
}
