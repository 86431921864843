import write from "../Icons/write.png";
import clean from "../Icons/clean.png";
import classify from "../Icons/classify.png";

export const info = [
    {
        "id": 1,
        "title": "Write Better Faster.",
        "subtitle": "Use the text generation tools to create great writing quickly.",
        "icon": write,
    },
    {
        "id": 2,
        "title": "Clean That Data!",
        "subtitle": "Clean large amounts of text or numeric data quickly and easily.",
        "icon": clean,
    },
    {
        "id": 3,
        "title": "Organize your Sheets.",
        "subtitle": "Create more organized sheets, using our tools to classify data.",
        "icon": classify,
    }
]