import React from 'react';
import './BlogPost.css';

export default function BlogPost(props) {
  return (
    <div style={{
        marginTop: 150,
    }}>
        <img src={props.post.img} height="50px" width="50px" />
        <p id="blogTitle" >
            {props.post.title}
        </p>
        <p id="blogDate" >
            {props.post.date}
        </p>
        <p id="blogContent" >
            {props.post.content}
        </p>    
    </div>
  )
}
