import React from 'react';
import './GettingStarted.css';
import { useNavigate } from 'react-router-dom';

export default function GettingStarted() {
    const navigate = useNavigate();

    return (
        <div className="gettingStartedContainer" >
            <p id="gsTitle">
                We're glad you're here!
            </p>
            <p id="gsIntro" >
                Here is a short guide to get you started with <span style={{color: '#0be06e'}}>Array</span>Assistant!
            </p>
            <div id="gsPluginContainer" >
                <p id="gsExplain" >
                    To find instructions on installing the plugin, click here:
                </p>
                <button id="gsButton" 
                    onClick={() => {
                        navigate("/dashboard/extensions");
                    }}
                >
                    Install Plugin
                </button>
            </div>
            <div id="gsWebContainer" >
                <p id="gsExplain" >
                    To learn more about the tools, the plugin, and the apps functionality, click here:
                </p>
                <button id="gsButton" 
                    onClick={() => {
                        navigate("/dashboard/docs");
                    }}
                >
                    Documentation
                </button>
            </div>
            <div id="gsContactContainer" >
                <p id="gsExplain" >
                    If you are having any other issues, our contact email is:
                </p>
                <p id="gsContactEmail" >
                    contact@arrayassistant.ai
                </p>
            </div>
        </div>
    )
}
