import React from 'react';
import "./Docs.css";

export default function Docs() {
    return(
        <div className='mainDocs'>
            <h2 className='title'>
                Docs
            </h2>
            <h3 className='subTitle'>
                1.0 Getting Started
            </h3>
            <div className='fgDiv'>
                <h3 className='introTitle'>
                    1.1 Creating an Account:
                </h3>
                <div className="contentDiv">
                    <h4 className='introMain'>
                        <span className='titleSpan'>1.1.0 Intro:</span> <br/>
                        To use Array Assistant, you first must create an account on our service. It is free to create an account
                        and all of our services use Google's end-to-end encryption. Below, we will talk about the best practices for 
                        making an account and where to go to get started.
                    </h4>
                    <h4 className='basicsa'>
                        <span className='titleSpan'>1.1.1 Create your Account:</span> <br/>
                        To create your account, head over to the create account page, which can be found by clicking <a id="docsLink" href="https://arrayassistant.ai/createAccount" >here</a>. Then, fill out the form
                        to create an account.
                    </h4>
                    <h4 className='basicsb'>
                        <span className='titleSpan'>1.1.2 Best Practices:</span> <br/>
                        When filling out the account form, there are some things to keep in mind. Firstly, remember to add you full name.
                        This will help us keep track of your account and can make account recovery a lot easier. Secondly, use an email address
                        that you are familiar with and will remember. Finally, create a password that is at least 8 charactars long and is something
                        that you will remember.
                    </h4>
                    <h4 className='basicsc'>
                        <span className='titleSpan'>1.1.3 Errors:</span> <br/>
                        The account can take a moment to create. If it is just taking a little long to load, let the system finish loading. Once the system is done 
                        loading, you will then be taken to the next page. If an error is returned, make sure that you have not used this email before on our service. 
                        If you have, you can reset your password by clicking <a id="docsLink" href="https://arrayassistant.ai/reset"> here</a>. Finally, make sure your password is long
                        enough and that you have agreed to the terms of service and privacy policy.
                    </h4>
                </div>
            </div>
            <div className='feDiv'>
                <h3 className='introTitle'>
                    1.2 Subscriptions:
                </h3>
                <div className='contentDiv'>
                    <h4 className='introMain'>
                        <span className='titleSpan'>1.2.0 Intro:</span> <br/>
                        Our services here at Array Assistant create a monthly usage scenario, so all of our products come in the form of subscriptions. 
                        This way, you can purchase as much, or as little, as you need to use each month. And, of course, you can cancel your subscription at any time!
                    </h4>
                    <h4 className='basicsa'>
                        <span className='titleSpan'>1.2.1 Tiers:</span> <br/> 
                        We offer three main subscription tiers, each offering a unique benefit. For those who use excel often, but not all the time, we have the 'Basic' tier.
                        This tier is great for a student, hobbyist, or anyone who used excel on a weekly basis. It offers 250 servers requests per month. Next up is our 'Pro' 
                        tier. This tier is perfect for the busy professional who uses excel in their everyday life. It offers 1000 requests per month, which is plently to last you
                        throughout a month of work. Finally, our 'Enterprise' tier is for people who constantly have to work on Excel. This tier offers unlimited requests which 
                        means you can keep going even during the most busy months.
                    </h4>
                    <h4 className='basicsb'>
                        <span className='titleSpan'> 1.2.2 Requests:</span> <br/> 
                        Our requests work in a simple way. Everytime you hit the generate button on our services, the request will be worth between 1 and 5 request tokens. However, 
                        most smaller requests will be worth only 1 request token. The scaling, between 1 and 5, respresents the size of the response returned by the request. Therefore, 
                        larger requests will require more tokens. 
                    </h4>
                </div>
            </div>
            <div className='feDiv'>
                <h3 className='introTitle'>
                    1.3 Installing the Plugin:
                </h3>
                <div className='contentDiv'>
                    <h4 className='introMain'>
                        <span className='titleSpan'>1.3.0 Intro:</span> <br/>
                        Array Assistant offers one main plugin for Microsoft Excel. This plugin can be used on the desktop Excel app or on 
                        the web version of Excel. It fits nicely into the task pane to help you speed up your work.
                    </h4>
                    <h4 className='basicsa'>
                        <span className='titleSpan'>1.3.1 Where to Install:</span> <br/> 
                        You can find the Array Assistant plugin on the Microsoft AppSource store by searching 'Array Assistant' or by 
                        clicking <a id="docsLink" href="https://appsource.microsoft.com/en-us/product/office/WA200005565?exp=kyyw&tab=Overview" >here</a>.
                    </h4>
                    <h4 className='basicsb'>
                        <span className='titleSpan'> 1.3.2 Instructions:</span> <br/> 
                        Once you have navigated to the store page for Array Assistant, click the 'Get it Now' button. From here, you will be 
                        directed to sign in to your Microsoft Account. Sign in and then you will be taken to a page that will install the plugin in Excel for you.
                        Once the install starts, Microsoft may open your desktop version of Excel or, if you don't have a desktop app installed, you may be directed to 
                        Excel in your browser. Once the install is complete, you should see the green Array Assistant logo in the top right corner of your screen. Click 
                        on this logo to open the taskpane and get started.
                    </h4>
                    <h4 className='basicsc'>
                        <span className='titleSpan'>1.3.3 Getting Help:</span> <br/> 
                        If you are having any troubled getting the plugin set up, you can contact us for help at any time. Our contact email is 'contact@arrayassistant.ai'.
                        If you think it is a problem with Microsoft, you can contact Microsoft's customer service for further help.
                    </h4>
                </div>
            </div>
            <div className='feDiv'>
                <h3 className='introTitle'>
                    1.4 Payment:
                </h3>
                <div className='contentDiv'>
                    <h4 className='introMain'>
                        <span className='titleSpan'>1.4.0 Intro:</span> <br/>
                        After creating an account, you will be taken to the subscription selection page. From here, you can select which subscription you are 
                        interested in purchasing. Click 'Buy Now' and you will be instantly redirected to the checkout.
                        Array Assistant uses Stripe's API to process payments. For you, this means that everything is securely and quickly processed!
                    </h4>
                    <h4 className='basicsa'>
                        <span className='titleSpan'>1.4.1 Payment Methods:</span> <br/> 
                        We take a number of different payment methods including Debits Cards, Credit Cards, Apple Pay, Google Pay, CashApp, Wire Transfers, and more!
                    </h4>
                    <h4 className='basicsb'>
                        <span className='titleSpan'> 1.4.2 Payment Issues:</span> <br/> 
                        If you have had any issues with the payment process or a recent monthly subscription payment, contact us at 'contact@arrayassistant.ai'. We will help 
                        you take care of any issues or refund any money that needs to be returned. NOTE: We can only refund the most recent month you have payed for, once a month ends 
                        we are no longer able to refund the money for that month.
                    </h4>
                </div>
            </div>
            <div className='feDiv'>
                <h3 className='introTitle'>
                    1.5 Signing In:
                </h3>
                <div className='contentDiv'>
                    <h4 className='introMain'>
                        <span className='titleSpan'>1.5.0 Intro:</span> <br/>
                        There are currently two places you can sign into the Array Assistant service. The first is on the web dashboard. The second 
                        is on the Microsoft Excel Array Assistant plugin.
                    </h4>
                    <h4 className='basicsa'>
                        <span className='titleSpan'>1.5.1 Web Dashboard:</span> <br/> 
                        To Sign In on the web dashboard, just navigate to our website and click the 'Log In' button in the top right corner. Then, fill 
                        in your email and password that you created your account with.
                    </h4>
                    <h4 className='basicsb'>
                        <span className='titleSpan'> 1.5.2 Plugin:</span> <br/> 
                        Upon opening the plugin, you will be shown some information about our app. From here, scroll down to find the login screen. Then, 
                        log in with your email and password that you used to create an account. If it is your first time signing into the plugin, you will be 
                        shown a tutorial on how to use the app which you can follow along with or skip.
                    </h4>
                </div>
            </div>
            <div className='feDiv'>
                <h3 className='introTitle'>
                    1.6 Getting Acquainted:
                </h3>
                <div className='contentDiv'>
                    <h4 className='introMain'>
                        <span className='titleSpan'>1.6.0 Intro:</span> <br/>
                        On the plugin, there are a number of different tools that you can use to manipulate your spreadsheets. In addition to these 
                        tools, we also have some more on the web dashboard.
                    </h4>
                    <h4 className='basicsa'>
                        <span className='titleSpan'>1.6.1 Plugin:</span> <br/> 
                        On the plugin, there are three key areas on interest. The first is the main layout which houses the routes to all the tools and other 
                        pages on the app. Simply find the tool or page you are looking for and click on it. To get back to the main menu, there is always a 'Home' 
                        button in the top left corner of the screen. The profile and settings pages are at the bottom and can be used to check remaining requests and to 
                        sign out.
                    </h4>
                    <h4 className='basicsb'>
                        <span className='titleSpan'> 1.6.2 Dashboard:</span> <br/> 
                        The web dashboard houses all of the main components in the navigation bar on the left side of the screen. To navigate to a route, simply click 
                        on it. The profile page with options such as signing out, cancelling a subscription, or upgrading a subscription can be found at the bottom left 
                        corner of the screen.
                    </h4>
                </div>
            </div>
            <h3 className='subTitle'>
                2.0 Tools
            </h3>
            <div className='fgDiv'>
                <h3 className='introTitle'>
                    2.1 Formula Generator:
                </h3>
                <div className="contentDiv">
                    <h4 className='introMain'>
                        <span className='titleSpan'>2.1.0 Intro:</span> <br/>
                        The Formula Generator is a tool that allows you to create a formula
                        simply by describing what you need said formula to do. It takes written
                        commands and turns them into ready-to-use formulas that can be copy and
                        pasted into the spreadsheet of your choice!
                    </h4>
                    <h4 className='basicsa'>
                        <span className='titleSpan'>2.1.1 Services:</span> <br/>
                        The output will depend on which service you are using. There
                        are two major spreadsheet services, Microsoft Excel and google
                        Sheets, and you should identify which one you are using. Once 
                        you have done so, simply click the button that matches you service.
                        These buttons can be found above the input area and are labelled 
                        accordingly.
                    </h4>
                    <h4 className='basicsb'>
                        <span className='titleSpan'>2.1.2 Input:</span> <br/>
                        The input is important as it is what your formula will be generated
                        from. You simply want to tell it what it is creating a formula to do,
                        and nothing more. Use plain english but do not form complete sentences. 
                        Describe your variables how you need them and be concise and to the point. 
                        Two good examples of how to prompt the Formula Generator tool would be as follows: <br/> <br/>
                    </h4>
                    <h4 className='exampleText'>
                        "Calculate the sum from A20 to A55"
                    </h4>
                    <h4 className='exampleText'>
                        "Find the maximum value of column C"
                    </h4>
                    <h4 className='basicsb'>
                        <br/> Try to keep the requests similar to this form and you should get the answers that you are looking for!
                    </h4>
                    <h4 className='basicsc'>
                        <span className='titleSpan'>2.1.3 Output:</span> <br/>
                        The output section is where your result will appear. It will only 
                        output the formula, beginning with the '=' character. This section allows
                        for you to simply copy and paste the formula from the generator into
                        the spreadsheet that you are currently creating.
                    </h4>
                </div>
            </div>
            <div className='feDiv'>
                <h3 className='introTitle'>
                    2.2 Formula Explainer:
                </h3>
                <div className='contentDiv'>
                    <h4 className='introMain'>
                        <span className='titleSpan'>2.2.0 Intro:</span> <br/>
                        The formula explainer tool allows you to have any formula, not just spreadsheet
                        formulas, explained! Simply input the function or formula that you don't understand,
                        and it will be explained in simple and concise terms. For more information on how
                        to use, see the following sections.
                    </h4>
                    <h4 className='basicsa'>
                        <span className='titleSpan'>2.2.1 Services:</span> <br/> 
                        The output will depend on which service you are using. There
                        are two major spreadsheet services, Microsoft Excel and google
                        Sheets. You do not have to specify the service that the equation
                        is for, the AI will take care of it for you.
                    </h4>
                    <h4 className='basicsb'>
                        <span className='titleSpan'> 2.2.2 Input:</span> <br/> 
                        The input for the Formula Explainer tool takes only inputs in the form of a sheets or excel equation.
                        For example: <br/> <br/>
                    </h4>
                    <h4 className='exampleText' > "=SUM(A10:A50)" </h4>
                    <h4 className='basicsb'>
                        <br/> All you have to submit is a formula. Once you have typed your formula in,
                        hit the 'Generate' button and allow time for the system to load your output.
                    </h4>
                    <h4 className='basicsc'>
                        <span className='titleSpan'>2.2.3 Output:</span> <br/> 
                        The output section is where your result will appear. For the Formula Explainer tool, the AI program will attempt
                        to explain your equation in the simplest terms possible. The ouput box is read-only, but you can copy the text from inside
                        the box by just selecting it and copying it!
                    </h4>
                </div>
            </div>
            <div className='feDiv'>
                <h3 className='introTitle'>
                    2.3 Problem Solver:
                </h3>
                <div className='contentDiv'>
                    <h4 className='introMain'>
                        <span className='titleSpan'>2.3.0 Intro:</span> <br/>
                        The Problem Solver tool is great for helping you figure out simple solutions to tricky problems. It takes an 
                        input, usually in the form of a question, and creates a solution perfect for a quick resolution. The output is in text 
                        format and, if needed, will be provided with steps. For more information on how to use, see the following 
                        instructions.
                    </h4>
                    <h4 className='basicsa'>
                        <span className='titleSpan'>2.3.1 Input:</span> <br/> 
                        The input should be entered in the form of a problem or question. The AI will then determine how to best 
                        help you in your specific case. You can ask it a question as you would a co-worker or a friend. For example, if 
                        I was trying to determine the purpose of a standard deviation calculation, I could say the following:
                    </h4>
                    <h4 className='exampleText' > "What does the standard deviation of a set of values represent?" </h4>
                    <h4 className='basicsb'>
                        <br/> All you have to submit is a question, such as the one above. After that, hit the 'Generate' button 
                        and the AI will do the rest of the heavy lifting! 
                    </h4>
                    <h4 className='basicsc'>
                        <span className='titleSpan'>2.3.2 Output:</span> <br/> 
                        The output section is where your result will appear. When using the Problem Solver tool, remember that the output 
                        will be tailored to the needs of the specific question. If the question is simple or concise, the answer may be the 
                        same. However, if the question is complex, the AI may determine that it is necessary to present it in steps. The 
                        output box is read-only, but you can copy from it to paste the answer elsewhere. 
                    </h4>
                </div>
            </div>
            <div className='feDiv'>
                <h3 className='introTitle'>
                    2.4 Text Generator:
                </h3>
                <div className='contentDiv'>
                    <h4 className='introMain'>
                        <span className='titleSpan'>2.4.0 Intro:</span> <br/>
                        The Text Generator tool allows you to generate large amounts of text quickly based off of a small input. It 
                        takes a small text input, usually a topic, question, or description, and turns it into a large body of text that 
                        can be added into your sheet quickly and easily. For more information on how to use this tool, see the following
                        instructions.
                    </h4>
                    <h4 className='basicsa'>
                        <span className='titleSpan'>2.4.1 Input:</span> <br/> 
                        The input can be in a number of different forms. However, the basic component is that the form is text based. 
                        This means that you can tell the AI what you want it to generate in natural language. For example, if I wanted to 
                        have the AI generate text about the general job activities of a project manager, I could input something like 
                        the following:
                    </h4>
                    <h4 className='exampleText' > "Project manager job responsibilities" </h4>
                    <h4 className='basicsb'>
                        <br/> All you have to do is enter a topic, subject, description, or question and the Text Generator will do the rest
                        of the heavy lifting for you. Additionally, if you have something in the sheet that you want it to talk about, you can 
                        use the 'Copy from Cell' button. Simple click on the cell that you want to copy from and click the button. Then, the 
                        text from the cell will appear within the input box. Once you have the desired input, click the 'Generate' button.
                    </h4>
                    <h4 className='basicsc'>
                        <span className='titleSpan'>2.4.2 Length:</span> <br/> 
                        As this tool generates text, we give you the option to specify the length. To do this, click the check box next to
                        where it says 'Specify Length'. After this, an input box will pop up that will allow you to specify the length 
                        of the text body generated. It takes a numeric input that is the number of words you want generated. Simply enter a 
                        value between 10 and 5000. The value will be read as you enter it, and remeber that longer generations use more 
                        requests.
                    </h4>
                    <h4 className='basicsc'>
                        <span className='titleSpan'>2.4.3 Output:</span> <br/> 
                        The output section is where your result will appear. When using the Text Generator tool, remember that the output 
                        will be tailored to the needs of the specific input. Additionally, if you input a length, the AI will take that into 
                        account as well. However, remember that more specific requests get better results. If you create a small input with no 
                        length specification, you may get an undesirable answer. The output will appear in the output box at the bottom of the 
                        tool. You can copy it out of this location or, to move it onto the sheet quickly, you can use the 'Copy to Cell' button.
                        To do so, simply select the cell you want to move the text to and click the 'Copy to Cell' button.
                    </h4>
                </div>
            </div>
            <h3 className='subTitle'>
                More Coming Soon...
            </h3>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
        </div>
    )
}

/*
Easy Copy and Paste setup for the layout of each section

<div className='feDiv'>
    <h3 className='introTitle'>
        1.2 Subscriptions:
    </h3>
    <div className='contentDiv'>
        <h4 className='introMain'>
            <span className='titleSpan'>2.2.0 Intro:</span> <br/>
        </h4>
        <h4 className='basicsa'>
            <span className='titleSpan'>2.2.1 Services:</span> <br/> 
        </h4>
        <h4 className='basicsb'>
            <span className='titleSpan'> 2.2.2 Input:</span> <br/> 
        </h4>
        <h4 className='basicsc'>
            <span className='titleSpan'>2.2.3 Output:</span> <br/> 
        </h4>
    </div>
</div>

*/