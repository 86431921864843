import React from 'react';
import BarNav from './BarComponent';
import Blog from './Blog';

export default function BlogContainer() {
    

    return (
        <div>
            <BarNav />
            <Blog />
        </div>
    )
}
