import React from 'react';
import BarNav from './BarComponent';
import BottomLinks from './BottomLinks';
import { Helmet } from 'react-helmet';

export default function AffiliateProgram() {
  return (
    <div>
        <div
            style={{
                marginTop: 120,
            }}
        >
            <div
                style={{
                    width: '70%',
                    marginLeft: '15%',
                    fontFamily: 'Inter, sans-serif',
                }}
            >
                <p
                    style={{
                        fontFamily: 'Inter Tight, sans-serif',
                        fontWeight: 500,
                        fontSize: 23,
                    }}
                >
                    Welcome to the Affiliate Program. 
                </p>
                <p>
                    We want to offer Creators a new way to work with Array Assistant! Introducing, the Affiliate Program. Here's how it works: <br/> <br/>
                    1. You create a post on one or more social platforms about our product. <br/>
                    2. Capture the performance stats and choose which one you would like to get paid from. <br/>
                    3. Email us the results and get paid! <br/>
                </p>
                <p>
                    <span style={{fontFamily: 'Inter Tight, sans-serif', fontWeight: 500, fontSize: 20}} >Information About Content Creation:</span> <br/>
                    Array Assistant is an app the allows users to integrate Artificial Intelligence into Microsoft Excel Spreadsheets. We offer a Basic tier for $5.00/month with a free trial to start out. 
                    To create a video, you can create a free trial yourself or use the content provided on our Youtube channel. <br/> <br/>
                    The content must show Array Assistant in a positive light. You must show our logo somewhere in the video or, put the link to our site somewhere on your account. Additionally, get an extra $5 per post by tagging us on the given platform!
                </p>
                <p>
                <span style={{fontFamily: 'Inter Tight, sans-serif', fontWeight: 500, fontSize: 20}} >Payment Information:</span> <br/>
                    You can choose to get paid based on one of three different metrics per post. The rates are below as follows: <br/> <br/>
                </p>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-start',
                    }}
                >
                    <div
                        style={{
                            width: '40%',
                        }}
                    >
                        <p>
                            Linkedin: <br/>
                            Views: $10 / 10,000 <br/>
                            Likes: $6 / 1,000 <br/>
                        </p>
                        <p>
                            Instagram: <br/>
                            Views: $9 / 100,000 <br/>
                            Likes: $6 / 1,000 <br/>
                            Shares: $7 / 1,000 <br/> 
                    
                        </p>
                    </div>
                    <div>
                        <p>
                            Youtube: <br/>
                            Views: $12 / 100,000 <br/>
                            Likes: $5 / 1,000 <br/> 
                        </p>
                        <p>
                            Tiktok: <br/>
                            Views: $5 / 100,000 <br/>
                            Likes: $5 / 1,000 <br/> 
                            Shares: $7 / 1,000 <br/> <br/>
                        </p>
                    </div>
                </div>
                <p>
                    You can email us at <span style={{fontFamily: 'Inter Tight, sans-serif', fontWeight: 500}} >contact@arrayassistant.ai</span>. When you email us, be sure to include 
                    the stats on you video, a link to the video, and your desired payment method. We will be sure to get back to you quickly!
                </p>
            </div>
        </div>
        <div className='aboutInfoSection' style={{marginTop: 80}}>
            <BottomLinks />
        </div>
        <BarNav />
        <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
    </div>
  )
}
