import React from 'react';
import BarNav from './BarComponent';
import { posts } from './data/blogData.js';
import { useNavigate } from 'react-router-dom';
import './Blog.css';

export default function Blog() {
  const navigate = useNavigate();
  
  return (
    <div className='blogMainContent' >
        <BarNav />
        <div className='blogMap'>
          {posts.map((post) => {
              return(
                  <BlogCard post={post} key={post.id} nav={navigate}/>
              )
          })}
        </div>
    </div>
  )
}

const BlogCard = (props) => {
  return(
    <div className='blogCardDiv' >
      <img src={props.post.img} id="blogCardImg" />
      <p id="blogCardTitle">
        {props.post.title}
      </p>
      <p id="blogCardPreview">
        {props.post.preview}
      </p>
      <button onClick={() => {
        props.nav(`/blog/${props.post.slug}`);
      }}>
        Read More
      </button>
    </div>
  )
}
