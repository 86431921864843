import React from 'react';
import { Helmet } from 'react-helmet'
import BottomLinks from './BottomLinks';
import BarNav from './BarComponent';

export default function ContactUs() {
  return (
    <div>
        <div className='ContactMain'
        >
            <Helmet>
                <html lang="en" />
                <title>Contact</title>
                <meta name="description" content="Get in touch with us!" />
                <meta name="keywords" content="contact array assistant, excel ai helper, ai for excel tools, machine learning for work, ai assistant, ai for productivity" />
            </Helmet>
            <div className='contactContent' 
                style={{
                    marginTop: 150,
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 300,
                    fontSize: 20,
                    width: '70%',
                    marginLeft: '15%',
                }}
            >
                <div
                    style={{
                        padding: 5,
                    }}
                >
                    <p
                        style={{
                            width: '40%',
                            fontFamily: 'Inter Tight, sans-serif',
                            fontSize: 24,
                            fontWeight: 500,
                            borderBottom: '1px solid black'
                        }}
                    >
                        Get in <span style={{color: '#0be06e'}} >Touch</span>
                    </p>
                    <p>
                        At Array Assistant, we take great pride in our customer service. We strive to offer the quick and easy access to professional assistance to all of our customers. Below, you can find a number of different ways to get in touch with us!
                    </p>
                    <p>
                        LinkedIn: ArrayAssistant
                    </p>
                    <p>
                        Instagram: arrayassistant.ai
                    </p>
                    <p>
                        Youtube: ArrayAssistant
                    </p>
                    <p>
                        TikTok: ArrayAssistant
                    </p>
                </div>
                <div
                    style={{
                        marginTop: 70,
                        color: 'white',
                        borderRadius: 10,
                        backgroundColor: 'black',
                        padding: 10
                    }}
                >
                    <p
                        style={{
                            width: '40%',
                            fontFamily: 'Inter Tight, sans-serif',
                            fontSize: 24,
                            fontWeight: 500,
                            borderBottom: '1px solid white',
                            height: 30,
                            marginTop: 5,
                        }}
                    >
                        Having Issues?
                    </p>
                    <p>
                        We always strive to take pride in our work and offer the very best products. That being said, we are a new company and we understand mistakes can be made. If you are having problems with any of our products, feel free to contact us at the email listed below!
                    </p>
                    <p>
                        Email: contact@arrayassistant.ai
                    </p>
                </div>
            </div>
            <div
                className='infoSection'
                style={{
                    width: '102%',
                    height: 250,
                    marginTop: 75,
                    marginLeft: '-1%',
                    color: 'white',
                    backgroundColor: '#1F1F1F' 
                }}
            >
                <BottomLinks />
            </div>
            <BarNav />
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
        </div>
    </div>
  )
}
