import React, { useEffect, useState, useCallback } from 'react';
import { TitleLink } from './StyledLink';
import './HomeMobile.css';
import axios from 'axios';
import { features } from './data/featureData';

import Logo2 from './Icons/Logo2.jpg';
import RightArrow from './Icons/RightArrow.png';
import HomeVideo from './media/NewHomeVid.mp4';

export default function HomeMobile() {
    const [email, setEmail] = useState();
    const [sent, setSent] = useState(false);

    const eHandle = useCallback((e) => {
        setEmail(e.target.value);
    })

    const handleSubmit = async () => {
        setSent(true);
        await axios.post("https://aiserver.herokuapp.com/email_mobile", {
            email: email
        })
            .then(() => {
                console.log("Email Sent")
            })
            .catch((err) => {
                console.log({ message: err.message })
            });
    }
    
    return (
        <div
        >
            <div
                className="mainMobileContainer"
            >
                <div
                    style={{
                        marginTop: 75, 
                        marginLeft: '5%',
                        width: '90%',
                        
                        borderRadius: 15, 
                        backgroundColor: 'black',
                        border: '1px solid black',
                    }}
                >
                    <video src={HomeVideo} alt="Array Assistant Features Demo" width="100%" height="100%" muted autoPlay playsInline style={{borderRadius: 16}} />
                </div>
                <div id="frontTextDiv"
                    style={{
                        width: '90%',
                        marginLeft: '6%'
                    }}
                >
                    <h1 
                        style={{ 
                            width: "97%", 
                            fontSize: 32,
                            fontWeight: 'bolder',
                            fontFamily: 'Inter Tight, sans-serif',
                            fontFeatureSettings: 'normal',
                            transform: 'scaleY(1.05)',
                            letterSpacing: '-1px',
                            marginTop: 20,
                            color: 'white',
                        }}
                        >
                        Supercharge Your <span style={{color: '#24D776', fontSize: 36}}>Spreadsheets</span> with <span style={{ color: "#24D776", fontSize: 36}}>AI</span>.
                    </h1>
                    <h3 
                        style={{ 
                            width: '95%',
                            position: 'relative',
                            top: 4,
                            lineHeight: 1.25,
                            fontWeight: 400,
                            fontFamily: 'Inter',
                            fontSize: 30,
                            color: 'white',
                        }}
                    >
                        The AI-Driven Partner for Spreadsheet Productivity. <br/> Save <span style={{ color: '#24D776' }}>Time</span>. Do <span style={{ color: "#24D776" }}>More</span>.
                    </h3>
                </div>
                <div id="emailCollectionContainer" >
                    <p className='mobileTextTitle' >
                        Thanks for stopping by on Mobile! <br/>
                    </p>
                    { sent ? (
                        <>
                            <p id="submitResponse" className='mobileText' >
                                Thank you for your interest in <span style={{color: '#24D776'}}>Array</span> Assistant! <br/> <br/>
                                We'll get that email over to you right away! Remember to check us out on your computer.
                            </p>
                        </>
                    ) : (
                        <>
                            <p className="mobileText" >
                                Array Assistant works best on laptop or desktop! <br/> <br/>
                                Enter your email below and we'll send you a link so you can try it out next time you're on your computer!
                            </p>
                            <div id="emailForm">
                                <input type="text" id="emailCollection" placeHolder="Enter Email..." onChange={(e) => {eHandle(e)}}/>
                                <div>
                                    <button id="emailSubmit" 
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className='mobileFeatures' > 
                    <p id="mobileFeaturesTitle" >
                        Features
                    </p>
                    <div className='mobileFeaturesContainer' >
                        {
                            features.map((feature) => {
                                return(
                                    <FeatureCard key={feature.id} feature={feature} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div
                style={{
                    width: '97%',
                    height: 90,
                    color: 'black',
                    position: 'fixed',
                    top: '1%',
                    left: '1.5%',
                    justifyContent: 'center',
                    background: 'white',
                    overflow: 'hidden',
                    display: 'flex',
                    borderRadius: 18
                }}
            >
                <img src={Logo2} height="40px" width="45px" alt="logo" 
                    style={{
                        marginLeft: 0,
                        marginTop: 22,
                        cursor: 'pointer',
                    }}
                />
                <h1
                    style={{
                        fontWeight: '500',
                        letterSpacing: 0,
                        color: '#09CB62',
                        fontFamily: 'Inter Tight, sans-serif',
                        marginLeft: 10,
                        marginTop: 22, 
                        fontSize: 35,
                    }}
                >
                    Array<span style={{color: 'black'}}>Assistant</span>
                </h1>
            </div>
        </div>
    )
}

const FeatureCard = (props) => {
    return(
        <div className='featureCardMobile' >
            <div id="featureCardMedia" >
                <video src={props.feature.video} alt="Feature showcase diplay" height="200" style={{borderRadius: 15, width: '100%', marginTop: 20, border: '1px solid black', backgroundColor: 'black'}} muted autoPlay playsInline loop/>
            </div>
            <p id="featureCardTitle" >
                {props.feature.title}
            </p>
            <p id="featureCardText">
                {props.feature.description}
            </p>
        </div>
    )
}
