import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import './Layout.css';
import { SideLink } from './ListLink';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../auth/firebase';

import Home from './Icons/home.png';
import Profile from './Icons/profile.png';
import Create from './Icons/create.png';
import Settings from './Icons/settings.png';
import Summary from './Icons/summary.png';
import Logo from './Icons/Logo2.jpg';
import Plugin from './Icons/plugin.png';
import Pricing from './Icons/pricing.png';
import Text from './Icons/Text2.png';

export default function Layout() {

    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate("/signIn");
            }
        })
    },[]);
    
    return(
        <>
            <nav className='nav' >
                <div className='navTop'>
                    <img src={Logo} alt="Array Assistant Logo" height="27px" width="27px" id="logoImage" onClick={() => {navigate("/dashboard/");}}/>
                    <h2 
                        className='headerTop'
                        style={{
                            fontFamily: 'Inter Tight, sans-serif',
                            color: 'white',
                        }}
                        onClick={() => {
                            navigate("/dashboard/");
                        }}
                    >                        
                        <span style={{color: '#09CB62'}}>Array</span>Assistant
                    </h2>
                </div>
                <div className='listDiv'>
                    <ul className='list'>
                        <li>
                            <SideLink 
                                to="/dashboard/"
                                className='home'
                                style={{
                                    marginTop: -10,
                                    width: '100%',
                                }}
                            >
                                <img src={Home} alt="icon" height="30" width="30" style={{ position: 'relative', top: 19, marginRight: 10, filter: 'invert(1)', }}/>
                                <h2 className='homeTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 300,
                                        color: 'white',
                                    }}
                                >
                                    Home
                                </h2>
                            </SideLink>
                        </li>
                        <li>
                            <SideLink 
                                to="/dashboard/extensions"
                                className='plugin'
                                style={{
                                    marginTop: -10,
                                }}
                            >
                                <img src={Plugin} alt="icon" height="24" width="24" style={{ position: 'relative', top: 24, marginRight: 12, marginLeft: 4, filter: 'invert(1)', }}/>
                                <h2 className='homeTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 300,
                                        color: 'white',
                                    }}
                                >
                                    Plugin
                                </h2>
                            </SideLink>
                        </li>
                        <h2 className='formSection'
                            style={{
                                fontFamily: 'Inter Tight, sans-serif',
                                fontWeight: 500,
                                color: 'white',
                            }}
                        >
                            Tools
                        </h2>
                        <li>
                            <SideLink 
                                to="/dashboard/formulatools"
                                className='formGen'
                            >
                                <img src={Create} alt="icon" height="26" width="26" style={{ position: 'relative', top: 19, marginRight: 6, filter: 'invert(1)', }}/>
                                <h3 className='formGenTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 300,
                                        color: 'white',
                                    }}
                                >
                                    Formula Tools
                                </h3>
                            </SideLink>
                            <SideLink 
                                to="/dashboard/texttools"
                                className='formGen'
                            >
                                <img src={Text} alt="icon" height="40" width="40" style={{ position: 'relative', top: 10, marginLeft: -6, marginRight: -2, filter: 'invert(1)', }}/>
                                <h3 className='formGenTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 300,
                                        color: 'white',
                                    }}
                                >
                                    Text Tools
                                </h3>
                            </SideLink>
                        </li>
                        <h2 className='infoSection'
                            style={{
                                fontFamily: 'Inter Tight, sans-serif',
                                fontWeight: 500,
                                color: 'white',
                            }}
                        >
                            Info
                        </h2>
                        <li>
                            <SideLink 
                                to="/dashboard/pricing"
                                className='pricing'
                            >
                                <img src={Pricing} alt="icon" height="30" width="30" style={{ position: 'relative', top: 19, marginRight: 2, filter: 'invert(1)', }}/>
                                <h3 className='pricingTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 300,
                                        color: 'white',
                                    }}
                                >
                                    Pricing
                                </h3>
                            </SideLink>
                        </li>
                        <li>
                            <SideLink 
                                to="/dashboard/docs"
                                className='docs'
                            >
                                <img src={Summary} alt="icon" height="30" width="30" style={{ position: 'relative', top: 19, marginRight: 2, filter: 'invert(1)', }}/>
                                <h3 className='docsTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 300,
                                        color: 'white',
                                    }}
                                >
                                    Docs
                                </h3>
                            </SideLink>
                        </li>
                        <li>
                            <SideLink 
                                to="/dashboard/inDev"
                                className='docs'
                            >
                                <img src={Settings} alt="icon" height="37" width="37" style={{ position: 'relative', top: 15, marginRight: -4, marginLeft: -4, filter: 'invert(1)', }}/>
                                <h3 className='docsTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 300,
                                        color: 'white',
                                    }}
                                >
                                    Settings
                                </h3>
                            </SideLink>
                        </li>
                    </ul>
                </div>
                <div className='accountSpace'>
                    <SideLink
                        to="/dashboard/account"
                        className='account'
                    >
                        <img src={Profile} alt="icon" height="30" width="30" style={{ position: 'relative', top: 24, left: 5, filter: 'invert(1)', }}/>
                        <h3 className='username'
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                color: 'white',
                            }}
                        >
                            User Account
                        </h3>
                    </SideLink>
                </div>
                <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
            </nav>

            <Outlet />
            
        </>
    )
};



/*
<li>
    <SideLink 
        to="/customerPortal/inDev"
        className='formExp'
    >
        <h3 className='formExpTitle'
            style={{
                fontFamily: 'Helvetica, Arial, sans-serif',
            }}
        >
            Bank
        </h3>
    </SideLink>
</li>
*/

/* Routes taken out for production:

<h2 className='textSection'
                            style={{
                                fontFamily: 'Inter Tight, sans-serif',
                                fontWeight: 500,
                            }}
                        >
                            Text
                        </h2>
                        <li>
                            <SideLink 
                                to="/dashboard/inDev"
                                className='formGen'
                            >
                                <img src={Text} alt="icon" height="32" width="32" style={{ position: 'relative', top: 19, marginRight: 0}}/>
                                <h3 className='formGenTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 500,
                                    }}
                                >
                                    Generate Text
                                </h3>
                            </SideLink>
                        </li>
                        <li>
                            <SideLink 
                                to="/dashboard/inDev"
                                className='formExp'
                            >
                                <img src={Summary} alt="icon" height="30" width="30" style={{ position: 'relative', top: 19, marginRight: 2}}/>
                                <h3 className='formExpTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 500,
                                    }}
                                >
                                    Summarize Text
                                </h3>
                            </SideLink>
                        </li>
                        <li>
                            <SideLink 
                                to="/dashboard/inDev"
                                className='formExp'
                            >
                                <img src={Grammar} alt="icon" height="27" width="27" style={{ position: 'relative', top: 19, marginRight: 5}}/>
                                <h3 className='formExpTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 500,
                                    }}
                                >
                                    Grammer Checker
                                </h3>
                            </SideLink>
                        </li>
                        <li>
                            <SideLink 
                                to="/dashboard/inDev"
                                className='formExp'
                            >
                                    <img src={Translate} alt="icon" height="30" width="30" style={{ position: 'relative', top: 19, marginRight: 2}}/>
                                    <h3 className='formExpTitle'
                                        style={{
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 500,
                                        }}
                                    >
                                       Translate
                                    </h3>
                            </SideLink>
                        </li>
                        <li>
                            <SideLink
                                to='/dashboard/inDev'
                                className='formBank'
                            >
                                <img src={Solved} alt="icon" height="27" width="27" style={{ position: 'relative', top: 15, marginRight: 5}}/>
                                <h3 className='formBankTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 500,
                                    }}
                                >
                                    Explainer
                                </h3>
                            </SideLink>
                        </li>
                        <h2 className='codeSection'
                            style={{
                                fontFamily: 'Inter Tight, sans-serif',
                                fontWeight: 500,
                            }}
                        >
                            Code
                        </h2>
                        <li>
                            <SideLink
                                to='/dashboard/inDev'
                                className='formBank'
                            >
                                <h3 className='formBankTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 500,
                                    }}
                                >
                                    Python
                                </h3>
                            </SideLink>
                        </li>
                        <li>
                            <SideLink
                                to='/dashboard/inDev'
                                className='formBank'
                            >
                                <h3 className='formBankTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 500,
                                    }}
                                >
                                    SQL + R
                                </h3>
                            </SideLink>
                        </li>
                        <li>
                            <SideLink
                                to='/dashboard/inDev'
                                className='formBank'
                            >
                                <h3 className='formBankTitle'
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 500,
                                    }}
                                >
                                    Sheet Automation
                                </h3>
                            </SideLink>
                        </li>

*/