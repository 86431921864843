import React, { useState, useEffect } from 'react';
import './Home.css';
import AtAGlance from './AtAGlance';
import GettingStarted from './GettingStarted';
import FeedbackPlugin from './FeedbackPlugin';
import { onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc} from 'firebase/firestore';
import { auth, db } from '../auth/firebase';
import { Helmet } from 'react-helmet';

import Color from './media/colorBackground1.png';

export default function Home() {
  const [userName, setUserName] = useState("");
  const [requests, setRequests] = useState();
  const [hours, setHours] = useState();
  const [email, setEmail] = useState();
  const [readyUI, setReadyUI] = useState(false);

  const currentDate = new Date();
  let hoursNum = Number(hours);
  const hoursFixed = hoursNum.toFixed(1);

  const welcomeMessage = `Welcome ${userName}`;
  const dateMessage = `${currentDate.toDateString()}`;
  const requestsMessage = `${requests} Remaining`;
  const emailMessage = `${email}`;
  const hoursSaved = `${hoursFixed} Hours`;
  

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if(user) {
        const docRef = doc(db, "customers", user.uid);
        const rawData = await getDoc(docRef);

        const data = rawData.data();
        setUserName(data.firstName);
        setRequests(data.requests);
        setEmail(data.userEmail);

        if(data.lifetimeRequests){
          const total = ( data.lifetimeRequests * 10 ) / 60;
          setHours(total);
        } else {
          setHours(0);
        }

        setReadyUI(true);
      }
    })
  })

  return(
    <div className='app'>
      <Helmet>
          <html lang="en" />
          <title>Dashboard</title>
          <meta name="description" content="Array Assistant Home Dashboard" />
          <meta name="keywords" content="excel dashboard for ai formula creator and ai tools assistant" />
      </Helmet>
      <div className='titleContainer' >
        <img src={Color} alt="background color" width="100%" height="150px" style={{borderRadius: 7.5}} />
        <p className='welcomeMessage'>
          { !readyUI ? "Loading... " : welcomeMessage}
        </p>
      </div>
      <div className='statsContainer' >
        <p className='statsDisplay'>
          Todays Date: <br/> <span className='displaySpan' >{ !readyUI ? "Loading... " : dateMessage}</span>
        </p>
        <p className='statsDisplay'>
          Requests: <br/> <span className='displaySpan' >{ !readyUI ? "Loading... " : requestsMessage}</span>
        </p>
        <p className='statsDisplay'>
          Hours Saved: <br/> <span className='displaySpan' >{ !readyUI ? "Loading... " : hoursSaved}</span>
        </p>
        <p className='statsDisplay'>
          Email: <br/> <span className='displaySpan' >{ !readyUI ? "Loading... " : emailMessage}</span>
        </p>
      </div>
      <div className='bulkContainer' >
        <div className='leftContainer' >
          <FeedbackPlugin />
          {/*
          <div className='toolsContainer' >
            <p className='toolTitle' >
              Getting to work...
            </p>
            <button className='toolButton'>
              Formula Generator
            </button>
            <button className='toolButton'>
              Formula Explainer
            </button>
            <button className='toolButton'>
              View Profile
            </button>
            <button className='toolButton'>
              Plugin Page
            </button>
          </div>
          */}
        </div>
        <div className='rightContainer' >
          <GettingStarted />
        </div>
      </div>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
      <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
    </div>
  )
}