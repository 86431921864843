import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BtmLink = styled(Link)`
    text-decoration: none;
    font-size: 15;
    font-weight: 300;
    font-family: Inter, sans-serif;
    color: white;
    &:hover {
        text-decoration: underline;
    }
    &:active {
        color: #4ad388;
    }
`;

export { BtmLink };