import React, { useState, useCallback } from 'react';
import './FeedbackPlugin.css';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { auth, db } from '../auth/firebase';

export default function FeedbackPlugin() {
    const [submitted, setSubmitted] = useState(false);
    const [input, setInput] = useState("")
    const [loading, setLoading] = useState(false);

    const iHandle = useCallback((e) => {
        setInput(e.target.value);
    })

    const setTicket = async () => {
        try {
            const email = auth.currentUser.email;
            const date = Timestamp.fromDate(new Date());
            const seconds = date.seconds;
            
            const docRef = doc(db, "feedback", `${seconds}`);
            const docData = {
                email: email,
                humanTime: date,
                comment: input,
            }

            await setDoc(docRef, docData);
        } catch (err) {
            console.log({ message: err.message });
        }
    }

    const submitFunction = () => {
        setLoading(true);
        setTicket()
            .then(() => {
                setLoading(false);
                setSubmitted(true);
            })
            .catch((err) => {
                console.log({ message: err.message });
                setLoading(false);
            })
    }

    return (
        <div className="feedbackContainer" >
            <p id="feedbackTitle" >
                Feedback:
            </p>
            { submitted ? (
                <p id="feedbackResponse" >
                    Thank You for your Feedback! <br/> <br/>
                    We will reach out to you soon about your concern or suggestion.
                </p>
            ) : (
                <div id="feedbackContainer" >
                    <p id="feedbackInstructions" >
                        If you have any feedback or would like to request a new tool, please submit a comment below:
                    </p>
                    <textarea type="text" id="feedbackInput" onChange={(text) => {iHandle(text)}} rows="5" placeholder="Let us know what you think..." />
                    <button id="feedbackButton" 
                        onClick={() => {
                            submitFunction();
                        }}
                    >
                        { loading ? "Loading..." : "Submit" }
                    </button>
                </div>
            )}
            
        </div>
    )
}
