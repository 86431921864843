import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import './PaymentWall.css';
import { getDoc, doc } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from '../auth/firebase.js';
import BottomLinks from './BottomLinks';
import { tiers } from './data/priceData';

export default function PaymentWall() {
    const [code, setCode] = useState();
    const navigate = useNavigate();
    const search = new URLSearchParams(window.location.search);

    useEffect(() => {
        const linkCode = search.get('code');
        if(linkCode){
            setCode(linkCode);
        }

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log(user.uid)

                const docRef = doc(db, "customers", user.uid);
                const data = await getDoc(docRef);

                const parsed = data.data();
                if(parsed.userSubscription) {
                    const sub = parsed.userSubscription;
                    if(sub){
                        navigate('/dashboard')
                    }
                }
            } 
        });
    },[]);

    const payLater = () => {
        signOut(auth)
            .then(() => {
                navigate("/");
            })
            .catch((err) => {
                console.log({ message: err.message })
            });
    };
    

    return (
        <div
            style={{
                background: 'white'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',

                }}
            >
                <button className="productPurchase"
                    onClick={() => {
                        payLater();
                    }}
                    style={{
                        width: 175,
                        fontSize: 17,
                        fontFamily: 'Inter, sans-serif',
                        border: '1px solid black',
                        marginTop: 20,
                        height: 35,
                        cursor: 'pointer',
                        backgroundColor: 'white',
                        color: 'black',
                        marginTop: 5,
                    }}
                >
                    &lt;&nbsp;Pay Another Time
                </button>
                <p
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 300,
                        fontSize: 20,
                        marginLeft: '20%',
                        borderRadius: 5,
                        color: 'white',
                        background: 'linear-gradient(33deg, #0BDA51, #3e7cab)',
                        marginTop: 0,
                        padding: 5,
                        width: '35%',
                        textAlign: 'center',
                    }}
                >
                    New Sale Now! Get Your First Week of Basic <span style={{fontFamily: 'Inter Tight, sans-serif', fontWeight: 300}} >Free</span>! Cancel anytime!
                </p>
            </div>
            <div className='pricingWallMainContent' >
                {tiers.map((tier) => {
                    return(
                        <Card tier={tier} key={tier.id} nav={navigate} code={code} />
                    )
                })}
            </div>
            <div className='aboutPricingInfo' >
                    <div id="aboutTextContainer" >
                        <p id="aboutPricingHeader" >
                            Options for every <span id="titleSpanPricing" >Budget</span>
                        </p>
                        <p id="aboutPricingDescription" >
                            Whether you are a student, a busy professional, or simply use Excel for a hobby project, we have you covered. Save hours of time at work all for less than the price of a coffee!
                            Get started by trying out Array Assistant for <span id="titleSpanPricing">Free</span> for your first two weeks. 
                            Then, you can simply upgrade or cancel your subscription anytime you want!
                        </p>
                    </div>
                </div>
            <div
            className='infoSection'
            style={{
                width: '103%',
                height: 250,
                position: 'relative',
                top: '80px',
                left: "-1.5%",
                margin: 0,
                color: 'white',
                backgroundColor: '#1F1F1F'
            }}
        >
            <BottomLinks />
        </div>
        <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
    </div>
    )
}

const Card = props => {
    return(
        <div>
            <div className="prodContainer" >
                { props.tier.recommended ? (
                    <div className='recContainer'>
                        <p id="prodTitlePop" >
                            {props.tier.title}
                        </p>
                        <p id="recSpan" >
                            {props.tier.rec}
                        </p>
                    </div>
                ) : (
                    <>
                        <p className="prodTitle" >
                            {props.tier.title}
                        </p>
                    </>
                )}
                <div
                    style={{
                        marginTop: 40,
                        marginLeft: '10%',
                        width: '80%'
                    }}
                >
                    <h2 style={{
                        fontSize: 39,
                        fontWeight: 500,
                        fontFamily: 'Inter, san-serif',
                        fontColor: props.tier.color,
                    }}>
                        {props.tier.requests}
                    </h2>
                    <h4 
                        style={{
                            position: 'relative',
                            top: -30,
                            fontWeight: 300,
                            fontFamily: 'Inter',
                        }}
                    >
                        Requests per Month
                    </h4>
                </div>
                { props.tier.promotion ? (
                    <div>
                        <h2
                            style={{
                                fontSize: 30,
                                fontWeight: 500,
                                fontFamily: 'Inter Tight',
                                marginLeft: '10%',
                                marginTop: 30,
                            }}
                        >
                            Free <s style={{fontFamily: 'Inter, sans-serif', fontWeight: 300}}>{props.tier.price}</s><span className='monthlySpan'>/Monthly </span>
                        </h2>
                    </div>
                ) : (
                    <>
                        <h2
                            style={{
                                fontSize: 30,
                                fontWeight: 500,
                                fontFamily: 'Inter Tight',
                                marginLeft: '10%',
                                marginTop: 30,
                            }}
                        >
                            {props.tier.price}<span className='monthlySpan'>/Monthly </span>
                        </h2>
                    </>
                )}
                <button className="frontPurchase"
                    onClick={() => {
                        if(props.code){
                            props.nav(`${props.tier.checkout}?code=${props.code}`)
                        } else {
                            props.nav(props.tier.checkout);
                        }
                    }}
                >
                    {props.tier.avail}
                </button>
            </div>
        </div>
    )
};
