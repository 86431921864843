import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../auth/firebase.js'
import { Outlet, useNavigate } from 'react-router-dom'
import { getDoc, doc } from 'firebase/firestore';
import './Account.css';
import axios from 'axios';

import Profile from './Icons/profile.png';

export default function Account() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [readyUI, setReadyUI] =useState(false);
    const [sub, setSub] = useState("");
    const [reqs, setReqs] = useState("");
    const [lim, setLim] = useState(false);
    const [created, setCreated] = useState();
    const [billing, setBilling] = useState();
    const [price, setPrice] = useState();

    const navigate = useNavigate();
    const currentDate = new Date();

    const dateMessage = `${currentDate.toDateString()}`;

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const docRef = doc(db, "customers", auth.currentUser.uid);
                const data = await getDoc(docRef);
                const parsed = data.data();
                const subID = parsed.subscriptionId;

                setEmail(parsed.userEmail);
                setName(parsed.firstName);
                setReqs(parsed.requests);
                setLim(parsed.monthlyLimit);
                setCreated(parsed.dateCreated);

                const sub = parsed.subscriptionType;
                switch(sub){
                    case "0":
                        setSub("Personal");
                        break;
                    case "1":
                        setSub("Professional");
                        break;
                    case "2":
                        setSub("Enterprise");
                        break;
                    default:
                        setSub("Unknown")
                        break;
                }

                await axios.post("https://aiserver.herokuapp.com/billing_info", {
                    subscription_id: subID
                })
                    .then((response) => {
                        const timestamp = response.data.next_billing_date
                        if(timestamp == "Unknown") {
                            setBilling("Unknown");
                        } else {
                          const date = new Date(timestamp * 1000);
                        setBilling(date.toDateString());  
                        }
                        
                        const unformattedPrice = response.data.next_price;
                        if(unformattedPrice == "Unknown") {
                            setPrice("Unknown");
                        } else {
                            const formattedPrice = String(unformattedPrice).replace("99", ".99");
                            setPrice(`$${formattedPrice}`);  
                        }
                    })
                    .catch((err) => {
                        console.log({ message: err.message });
                    })
                
                setReadyUI(true);
            } else {
                navigate("/signIn");
            }
        });
    })

    const signOutFunction = useCallback(() => {
        signOut(auth)
            .then(() => {
                navigate("/signIn");
            });
    });

    const emailFunction = () => {
        window.open("mailto:contact@arrayassistant.ai");
    }
    
    return(
        <div className='mainAcc'>
            <div className='topContainer' >
                <div className='profileContainer' >
                    <img src={Profile} alt="profile icon" width="120px" height="120px" style={{borderRadius: 10, marginLeft: 15, marginTop: 15}} />
                    <div id="profileInfo" >
                        <p id="profileName" >
                            { readyUI ? name : "Loading..." }
                        </p>
                        <p id="profileEmail" >
                            { readyUI ? email : "Loading..." }
                        </p>
                    </div>
                </div>
                <div className='dateContainer' >
                    <p id="dateTitle" >
                        Today's Date
                    </p>
                    <p id="dateMessage" >
                        {dateMessage}
                    </p>
                </div>
            </div>
            <div className='middleContainer' >
                <div className='infoContainer' >
                    <p id="infoTitle" >
                        Account Information:
                    </p>
                    <div className='informationDiv' >
                        <div className="infoGroup">
                            <p id="emailText" >
                                Email: 
                            </p>
                            <p id="infoSpan" >
                                { readyUI ? email : "Loading..." }
                            </p>
                        </div>
                        <div className="infoGroup">
                            <p id="requestsText" >
                                Requests: 
                            </p>
                            <p id="infoSpan" >
                                { readyUI ? reqs : "Loading..." }
                            </p>
                        </div>
                        <div className="infoGroup">
                            <p id="createdText" >
                                Date Created: 
                            </p>
                            <p id="infoSpan" >
                            { readyUI ? created : "Loading..." }
                            </p>
                        </div>
                        <div className="infoGroup" id="bottomSpan" >
                            <p id="subscriptionText" >
                                Subscription: 
                            </p>
                            <p id="infoSpan" >
                                { readyUI ? sub : "Loading..." }
                            </p>
                        </div>
                        <div className="infoGroup" id="bottomSpan" >
                            <p id="subscriptionText" >
                                Next Billing Date: 
                            </p>
                            <p id="infoSpan" >
                                { readyUI ? billing : "Loading..." }
                            </p>
                        </div>
                        <div className="infoGroup" id="bottomSpan" >
                            <p id="subscriptionText" >
                                Next Amount: 
                            </p>
                            <p id="infoSpan" >
                                { readyUI ? price : "Loading..." }
                            </p>
                        </div>
                    </div>
                </div>
                <div className='optionsContainer' >
                    <p id="optionsTitle" >
                        Options
                    </p>
                    <div className='optionsButtonsContainer' >
                        <button className="optionsButton" 
                            id='topButton'
                            onClick={() => {
                                navigate("/dashboard/pricing")
                            }}
                        >
                            Upgrade Subscription
                        </button>
                        <button className="optionsButton" 
                            onClick={() => {
                                emailFunction();
                            }}
                        >
                            Contact Customer Service
                        </button>
                        <button className="optionsButton" 
                            onClick={() => {
                                navigate("/cancel")
                            }}
                        >
                            Manage Subscription
                        </button>
                        <button className="optionsButton" 
                            onClick={() => {
                                signOutFunction();
                            }}
                        >
                            Sign Out
                        </button>
                    </div>
                </div>
            </div>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
        <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
        </div>
    )
};

/*
Old Account page

<div className='headerDiv'
    style={{
        position: 'realtive',
        top: 30,
        fontSize: 30
    }}
>
    Account
</div>
    <>
        <div className='signedIn'>
            <h3 className='topPage'>
                <div id="topContainer">
                    <div className='accountDisplay'>
                        <div className="accountBar">
                            <h3>
                                Information:
                            </h3>
                        </div>
                        <div className="accountBar" >
                            <h4 className='labelDisplay'>
                                Name: 
                            </h4>
                            <h4 className="inputReturn" >
                                { readyUI ? name : "Loading..." }
                            </h4>
                        </div>
                        <div className="accountBar" >
                            <h4 className='labelDisplay'>
                                Email:
                            </h4>
                            <h4 className="inputReturn" >
                                { readyUI ? email : "Loading..." }
                            </h4>
                        </div>
                        <div className="accountBar" >
                            <h4 className='labelDisplay'>
                                Subscription: 
                            </h4>
                            <h4 className='inputReturn'>
                                { readyUI ? sub : "Loading..." }
                            </h4>
                        </div>
                        <div className="accountBar" id="accountBarBottom" >
                            <h4 className='labelDisplay'>
                                Requests: 
                            </h4>
                            <h4 className="inputReturn" >
                                { readyUI ? reqs + " Remaining" : "Loading..." } { readyUI ? (<span style={{color:"#899499"}}> { lim ? " - You have reached you monthly limit. Upgrade to get more requests!" : "" } </span>) : ("")}
                            </h4>
                        </div>
                    </div>
                    <div className="optionsContainer" >
                        <h3>
                            Options:
                        </h3>
                        <button 
                            className='optionsButton'
                        >
                            Billing
                        </button>
                        <button 
                            className='optionsButton'
                            onClick={() => signOutFunction()}
                        >
                            Sign Out
                        </button>
                        <button
                            className='optionsButton'
                            onClick={() => upgradeFunction()}
                        >
                            Upgrade
                        </button>
                        <button
                            className='optionsButton'
                            onClick={() => cancelFunction()}
                        >
                            Cancel Subscription
                        </button>
                    </div>
                </div>
            </h3>
        </div>
    <Outlet />
</>
*/