export const tiers = [
    {
        "id": 1,
        "title": "Basic",
        "requests": "200",
        "description": "Access to our Best Tools, Plugins, and Spreadsheet Resources!",
        "price": "$4.99",
        "link": "/personal",
        "avail": "Upgrade",
        "color": "white",
        "trial": false,
        "type": 0,
    },
    {
        "id": 2,
        "title": "Pro",
        "requests": "1000",
        "description": "Same Great Access, with up to 1000 Requests Each Month!",
        "price": "$14.99",
        "link": "/professional",
        "avail": "Upgrade",
        "color": "#008FFF",
        "trial": true,
        "divBacking": "divBackingMiddle",
        "type": 1,       
    },
    {
        "id": 3,
        "title": "Enterprise",
        "requests": "Unlimited",
        "description": "Increase Company Productivity Today!",
        "price": "$49.99",
        "link": "/enterprise",
        "avail": "Upgrade",
        "color": "white",
        "trial": false,
        "type": 2,
    }
]