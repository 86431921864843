import * as React from 'react';
import { useState, useEffect } from 'react';
import './PaymentForm.css';
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Navigate } from 'react-router-dom';
import { auth, db } from '../auth/firebase';
import { doc, updateDoc } from 'firebase/firestore';


export default function PaymentForm(type) {
    const [success, setSuccess] = useState(false);
    const [reqNum, setReqNum] = useState(200);
    const [code, setCode] = useState();
    const search = new URLSearchParams(window.location.search);

    const dHandle = (e) => {
        setCode(e.target.value);
    } 

    const tier = type.type;

    const live = `https://arrayassistant.ai/check?type=${tier}&code=${code}`;
    const test = `http://localhost:3000/check?type=${tier}&code=${code}`;

    console.log("Tier:" + tier);

    useEffect(() => {
        const linkCode = search.get('code');
        const input = document.getElementById('codeInput');
        if(linkCode){
            input.value = linkCode;
        }

        switch(tier){
            case 0:
                setReqNum(100);
                break;
            case 1:
                setReqNum(1000);
                break;
            case 2:
                setReqNum(1000000);
                break;
            default:
                setReqNum(200);
                break;
        }
    }, [])

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
        
        e.preventDefault();
        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: live,
            }
        })
        console.log(error)
        

    };

    return (
        <>
            { !success ? (
                <>
                    <form onSubmit={handleSubmit}>
                        <fieldset 
                            className="FormGroup"
                        >
                            <div
                                className="FormRow"
                            >
                                <PaymentElement />
                                <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet"></link>
                            </div>
                        </fieldset>
                        <div id="discountCodeContainer" >
                            <p id="discountCodeTitle" >
                                Discount Code:
                            </p>
                            <input type="text" id="codeInput" onChange={(e) => {dHandle(e)}} />
                        </div>
                        <button
                            className='buyButton'
                        >
                            Purchase
                        </button>
                        <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet"></link>
                    </form>
                </>
            ) : (
                <>
                    <h2>
                        Thanks for purchasing your subscription!
                    </h2>
                    <Navigate to="/dashboard/" />
                </>
            )}
        </>
    )
}

/*
Here is the old payment code for professional and enterprise: 

} else {
            e.preventDefault();
            const { error } = await stripe.confirmSetup({
                elements, 
                confirmParams: {
                    return_url: live
                }
            })
            console.log(error);

            if (!error) {
                try {
                    const docRef = doc(db, "customers", auth.currentUser.uid);

                    await updateDoc(docRef, {
                        userSubscription: true,
                        requests: reqNum,
                        subscriptionType: tier
                    });

                    setSuccess(true);

                } catch (err) {
                    console.log({ message: err.message });
                };
            } else {
                console.log({ message: error.message });
            };
        }
*/
