import * as React from 'react';
import BarNav from './BarComponent';
import BottomLinks from './BottomLinks';
import { Helmet } from 'react-helmet';
import { features } from './data/featureData';

export default function Features() {
    return(
        <div className='featuresMain' style={{maxWidth: '1920px', margin: 'auto'}}>
            <Helmet>
                <html lang="en" />
                <title>Plugin</title>
                <link rel="canonical" href="http://arrayassistant.ai/plugin" />
                <meta name="description" content="Learn more about the tools and AI features of Array Assistant!" />
                <meta name="keywords" content="formula creator bot, ai formula creator, ai tools, ai for spreadsheets, ai for excel, microsoft excel, office 365 ai" />
            </Helmet>
            <div
                style={{
                    height: 500,
                    width: '102%',
                    position: 'relative',
                    background: 'linear-gradient(90deg, #000000, #333333)',
                    marginLeft: '-1%',
                }}
            >
                <div
                    style={{
                        marginLeft: '0%',
                        position: 'relative',
                        top: 380,
                        width: '100%',
                        textAlign: 'center',
                        height: 70,
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <h1
                        style={{
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 300,
                            fontSize: 58,
                            height: 50,
                            width: '8em',
                            marginLeft: '3%',
                        }}
                    >
                        Excel. <span style={{color: '#0be06e'}}>Simplified</span>.
                    </h1>
                    <p
                        style={{
                            fontFamily: 'Inter, sans-serif',
                            fontSize: 22,
                            fontWeight: 300,
                            height: 50,
                            width: '20em',
                            textAlign: 'left',
                            marginTop: 50,
                            marginRight: 20
                        }}
                    >
                        Learn more about the features and tools of the Array Assistant plugin.
                    </p>
                </div>
            </div>
            <div id="cardContainer" 
                style={{
                    height: '100%',
                    width: '100%',
                    marginTop: 40,
                    marginBottom: 10
                }}
            >
                {features.map((feature) => {
                    return(
                        <FeatureCard key={feature.id} feature={feature} />
                    )
                })}
            </div>
            <div
                className='infoSection'
                style={{
                    width: '102%',
                    height: 250,
                    marginTop: 125,
                    marginLeft: '-1%',
                    color: 'white',
                    backgroundColor: '#1F1F1F'
                }}
            >
                <BottomLinks />
            </div>
            <BarNav />
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
        </div>
    )
}

function FeatureCard(props) {
    return(
        <div id="featureContainer"
            style={{
                width: '100%',
                height: 360,
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: 70,
            }}
        >
            { props.feature.side ? (
                <>
                    <div id="textContainer"
                        style={{
                            width: '37%',
                            height: 250,
                            borderRadius: 14,
                            border: 'none',
                            backgroundColor: 'white',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 300,
                            fontSize: 23,
                            color: 'black',
                            paddingTop: 5,
                            paddingLeft: 25, 
                            paddingRight: 25,
                            marginTop: 45,
                        }}
                    >
                        <p
                            style={{
                                fontFamily: 'Inter Tight, sans-serif',
                                fontWeight: 500,
                                fontSize: 26,
                            }}
                        >
                            {props.feature.title}
                        </p>
                        <p>
                            {props.feature.description}
                        </p>
                    </div>
                    <div id="mediaContainer"
                        style={{
                            width: '40%',
                            height: '350',
                        }}
                    >
                        <video src={props.feature.video} alt="Feature showcase diplay" height="340" style={{borderRadius: 25, width: '100%', marginTop: 20, border: '1px solid black', backgroundColor: 'black'}} controls="controls" autoPlay={true} loop/>
                    </div>
                </>
            ) : (
                <>
                    <div id="mediaContainer"
                        style={{
                            width: '40%',
                            height: '350',
                        }}
                    >
                        <video src={props.feature.video} alt="Feature showcase diplay" height="340" style={{borderRadius: 25, width: '100%', marginTop: 20, border: '1px solid black', backgroundColor: 'black'}} controls="controls" autoPlay={true} loop/>
                    </div>
                    <div id="textContainer"
                        style={{
                            width: '37%',
                            height: 250,
                            borderRadius: 14,
                            border: 'none',
                            backgroundColor: 'white',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 300,
                            fontSize: 23,
                            color: 'black',
                            paddingTop: 5,
                            paddingLeft: 25, 
                            paddingRight: 25,
                            marginTop: 45,
                        }}
                    >
                        <p
                            style={{
                                fontFamily: 'Inter Tight, sans-serif',
                                fontWeight: 500,
                                fontSize: 26,
                            }}
                        >
                            {props.feature.title}
                        </p>
                        <p>
                            {props.feature.description}
                        </p>
                    </div>
                </>
            )}
        </div>
    )
}