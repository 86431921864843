import React, { useState, useCallback, useEffect } from 'react';
import './PDFGenius.css';
import axios from 'axios';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../auth/firebase';

export default function PDFGenius() {
    const [loading, setLoading] = useState(false);
    const [answer, setAnswer] = useState();
    const [docs, setDocs] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [userInput, setUserInput] = useState();
    const [user, setUser] = useState();

    const url = "https://aipdf-702b07b414e5.herokuapp.com/pdfbot";
    const config = {
        headers: {'content-type':'multipart/form-data', 'enctype':'multipart/form-data'}
    };

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(user) {
                setUser(user.uid);
            } else {
                console.log("User does not exist.")
            }
        })
    }, [])

    const inputHandle = useCallback((e) => {
        setUserInput(e.target.value);
    })

    const fileFunction = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const fetchRequest = async () => {
        setLoading(true);
        const formData = new FormData();

        formData.append('user', user);
        formData.append('file', selectedFile);
        formData.append('userRequest', userInput);

        await axios.post(url, formData, config)
            .then((data) => {
                console.log(data);
                console.log("Response:" + data);
                setAnswer(data.data.answer);
                setDocs(data.data.documents);
                setLoading(false);
            })
            .catch((err) => {
                console.log({ message: err.message });
                setLoading(false);
            });
    }

    return (
        <div className="pdfMain" >
            <p id="pdfMainTitle" >
                PDF Genius
            </p>
            <p id="pdfStepOne" >
                Select a PDF:
            </p>
            <div>
                <label class="file">
                    <input type="file" id="fileInput" aria-label="File browser example" onChange={(upload) => {
                        fileFunction(upload);
                    }}/>
                    <span class="file-custom"></span>
                </label>
            </div>
            <p id="pdfStepTwo" >
                Ask any question about the pdf:
            </p>
            <div>
                <textarea 
                    id="textInput" 
                    type="text"
                    placeholder="Ask a question..."
                    onChange={(text) => {
                        inputHandle(text)
                    }} 
                    rows={4}
                    cols={40}
                />
            </div>
            <button id="pdfSubmitButton"
                onClick={() => {
                    fetchRequest();
                }}
            >
                Submit
            </button>
            <p id="pdfAnswerTitle">
                Answer:
            </p>
            <p id="pdfAnswerText">
                { !loading ? answer : "loading..." }
            </p>
        </div>
    )
}
