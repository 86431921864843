import * as React from 'react';
import './formulaBank.css';

export default function FormulaBank() {
    return(
        <div className='mainBank'>
            <h2 className='titleBank'>
                Formula Bank
            </h2>
        </div>
    )
}