import * as React from 'react';
import "./Extensions.css";

import MS from './media/MS_AppSource.png';

export default function Extensions() {
    return(
        <div id='mainExt'>
            <div className='titleDiv'>
            <h2 className='title'
                style={{
                    fontFamily: 'Inter Tight, sans-serif',
                    fontWeight: 500,
                }}
            >
                <span style={{color: '#0be06e'}}>Excel</span> Plugin
            </h2>
            <h4 className='description'
                style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 500,
                }}
            >
                On this page, you can learn a little more about installing the Excel plugin and getting started!
            </h4>
            </div>
            <div className='extensionsDiv'>
                <div className='excelSection'>
                    <h3 className='excelTitle'
                        style={{
                            fontFamily: 'Inter Tight, sans-serif',
                            fontWeight: 300,
                        }}
                    >
                        Installing
                    </h3>
                    <ol className='instructionsList'>
                        <li>
                            <h4 className='excelDes' >
                                Make sure you have access to Microsoft Excel. <br/>
                            </h4>
                        </li>
                        <li>
                            <h4 className='excelDes' >
                                Head over to the <span style={{color: '#0be06e'}}>Array</span>Assistant page on the Microsoft AppSource store.
                            </h4>
                        </li>
                        <button id="toExcelStore" 
                            onClick={() => {
                                window.open("https://appsource.microsoft.com/en-us/product/office/WA200005565?exp=kyyw&tab=Overview");
                            }}
                        >
                            <img src={MS} alt="Microsoft store badge" width="180px" height="75px" style={{borderRadius: 5}} />
                        </button>
                        <li>
                            <h4 className='excelDes' >
                                Once there, click the blue "Get It Now" button. <br/>
                            </h4>
                        </li>
                        <li>
                            <h4 className='excelDes' >
                                Microsoft will guide you through the installation process. <br/>
                            </h4>
                        </li>
                        <li>
                            <h4 className='excelDes' >
                                To get started on the plugin, check out the section to the right.
                            </h4>
                        </li>
                    </ol>
                </div>
                <div id='pluginGettingStarted'>
                    <h3 className='excelTitle'
                        style={{
                            fontFamily: 'Inter Tight, sans-serif',
                            fontWeight: 300,
                        }}
                    >
                        Getting Started
                    </h3>
                    <h4 className='excelDes'
                        style={{
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 500,
                        }}
                    >
                        After installing the plugin and opening it in Excel, you should see the Array Assistant logo in the top, right corner of your screen. <br/> <br/>
                        To get started, click on the logo. A taskpane on the right side of the screen should appear. <br/> <br/>
                        From here, you will first see a welcome screen. Scroll to the bottom of the screen using either the 'Next' buttons, or by scrolling with the mouse. <br/> <br/>
                        Now you should see the login screen and you can login with your account. <br/> <br/>
                        If you are a first time user, you will be shown a tutorial. You can follow along to get a better idea of how the plugin works, or you can skip it by clicking 'Skip'. <br/> <br/>
                    </h4>
                </div>
            </div>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
        </div>
    )
}