import { Link } from 'react-router-dom';
import styled from 'styled-components';

const TitleLink = styled(Link)`
    text-decoration: none;
    color: black;
`;

const SubLink = styled(Link)`
    text-decoration: none;
    font-family: Inter, sans-serif;
    color: black;
    justify-content: center;
    &:hover {
        color: #818181;
    }
    &:active {
        text-decoration: underline;
        color: #008fff;
    }
`;

const SignLink = styled(Link)`
    text-decoration: none;
    font-family: Inter, sans-serif;
    color: black;
    justify-content: center;
    font-weight: 300,
    background-color: #4ad388;
    &:hover {
        background-color: #47aa74;
    }
    &:active{
        color: white;
    }
`;

const SwitchLink = styled(Link)`
    text-decoration: none;
    color: #008fff;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 17px;
    height: 100%;
    &:hover {
        text-decoration: underline;
    }
    &:active {
        color: #4ad388;
        text-decoration: underline;
    }
`;

export { TitleLink, SubLink };
export { SignLink };
export { SwitchLink };