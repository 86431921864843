import React from 'react';
import BarNav from './BarComponent';
import BottomLinks from './BottomLinks';
import './Home.css';
import RightArrow from './Icons/RightArrow.png';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { info } from './data/homeData';

import HomeVideo from './media/NewHomeVid.mp4';
import Image2 from './media/cA3.jpg';
import badge from './media/PartnerBadge.png';
import Osu from './media/OsuLogo.png';

export default function HomeFront() {
    const navigate = useNavigate();

    return(
        <div className='homeContainer'>
            <Helmet>
                <html lang="en" />
                <title>Array Assistant</title>
                <meta name="description" content="Welcome to Array Assistant, the go-to AI tool for Microsoft Excel Spreadsheets! Click here to learn more." />
                <meta name="keywords" content="excel ai assistant, formula creator bot, ai formula creator, ai excel formula maker, chat gpt for excel, microsoft excel assistant" />
            </Helmet>
            <div className='pageContainer'>
                <div id="frontPageDiv"
                    style={{
                        marginTop: 100,
                        width: '100%',
                        height: 800,
                        justifyContent: 'center'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            position: 'relative',
                            top: 80,
                            width: '100%',
                            height: 500,
                            justifyContent: 'space-around'
                        }}
                    >
                        <div id="frontTextDiv"
                            style={{
                                width: '650px',
                                marginLeft: '5%'
                            }}
                        >
                            <h1 
                                style={{ 
                                    width: "90%", 
                                    fontSize: 54,
                                    fontWeight: 'bolder',
                                    fontFamily: 'Inter Tight, sans-serif',
                                    fontFeatureSettings: 'normal',
                                    transform: 'scaleY(1.05)',
                                    letterSpacing: '-1px',
                                    marginTop: 20
                                }}
                                >
                                Supercharge Your <span style={{color: '#24D776', fontSize: 58}}>Spreadsheets</span> with <span style={{ color: "#24D776", fontSize: 58}}>AI</span>.
                            </h1>
                            <h3 
                                style={{ 
                                    width: '60%',
                                    position: 'relative',
                                    top: 4,
                                    lineHeight: 1.25,
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                    fontSize: 30
                                }}
                            >
                                The AI-Driven Partner for Spreadsheet Productivity. <br/> Save <span style={{ color: '#008FFF' }}>Time</span>. Do <span style={{ color: "#008FFF" }}>More</span>.
                            </h3>
                            <button
                                id="signUpToday"
                                onClick={() => {
                                    navigate("/createAccount");
                                }}
                            >
                                <h5
                                    style={{
                                        position: 'relative',
                                        top: -18,
                                        fontSize: 20,
                                        marginRight: 7,
                                        marginLeft: 8,
                                        color: 'black',
                                        fontFamily: 'Inter Tight, sans-serif',
                                        fontWeight: 500
                                    }}
                                >
                                    Try For Free
                                </h5>
                                <img src={RightArrow} alt="icon" height="30" width="50" id="arrow"
                                    style={{ 
                                        position: 'relative', 
                                        top: 16,
                                    }}
                                />
                            </button>
                            <div
                                style={{
                                    height: 65,
                                    marginTop: 65,
                                    marginLeft: 5,
                                    width: 600,
                                    borderLeft: '1px solid black',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <img id="partnerBadge" src={badge} height="110" width="200" 
                                    style={{
                                        marginTop: -24,
                                    }}
                                />
                            </div>
                        </div>
                        <div id="frontContentDiv"
                            style={{
                                height: 365,
                                width: '44.5%',
                                backgroundColor: 'white',
                                position: 'relative',
                                top: 30,
                                marginRight: '3%'
                            }}
                        >
                            <video src={HomeVideo} height="378px" width="100%" controls="controls" autoPlay={true} loop style={{borderRadius: 27}}/>
                        </div>
                    </div>
                </div>
                <div id="styledDiv">
                    <div id="subFrontDiv">
                        <h1 id="subHeaderOne" style={{fontFamily: 'Inter Tight, sans-serif', fontWeight: 'bolder', fontSize: 40}}>
                            Revolutionize Your Workflow <span style={{ color: 'white', fontSize: 42 }}>Today</span>.
                        </h1>
                        <div style={{ display: 'flex', margin: 'auto', justifyContent: 'center', position: 'relative', top: 40 }}>
                            <div>
                                <div id="visualsOne">
                                    <img src={Image2} alt="Array Assistant team working" height="100%" width="100%" style={{borderRadius: 5}} />
                                </div>
                                <div id="visualsTwo">
                                    <h2 id="quote" style={{ width: '75%', fontFamily: 'Inter', fontWeight: '500', marginTop: 15}}>
                                        "All the Tools you need, at your Fingertips."
                                    </h2>
                                </div>
                            </div>
                            <div id="subTextDes" style={{ justifyContent: 'center'}}>
                                <h3 style={{ position: 'relative', top: '18%', fontFamily: 'Inter', fontWeight: 500}}>
                                    Create Formulas, Clean Data, Summarize Text, Explain Problems, Design Automations, and the list goes on! <br/> <br/>
                                    Whether you are a professional, a student, or simply someone who uses spreadsheets, Array Assistant can help you!
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="thirdFrontDiv"
                    style={{
                        height: 600,
                        width: '102%',
                        position: 'relative',
                        top: 250,
                        left: '-1%',
                        textAlign: 'center',
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                        backgroundColor: 'white',
                        marginBottom: 200
                    }}
                >
                    <div id="howTitle"
                        style={{
                            backgroundColor: 'transparent'
                        }}
                    >
                        <h2
                            style={{
                                fontSize: 36,
                                fontFamily: 'Inter, sans-serif',
                            }}
                        > 
                            Why <span style={{ color: '#09CB62', fontSize: 39 }} > Join </span> Array Assistant?
                        </h2>
                    </div>
                    <div id="testSection"
                        style={{
                            position: 'relative',
                            background: '#F3F5F9',
                            height: 450,
                            width: '102%',
                            left: '-1%',
                            top: -10,
                            display: 'flex'
                        }}
                    >
                        <div id="leftTest"
                            style={{
                                height: 400,
                                width: '40%',
                                margin: 'auto',
                                textAlign: 'left',
                                paddingLeft: 40,
                                paddingTop: 150
                            }}
                        >
                            <h1
                                style={{ 
                                    color: '#008FFF',
                                    fontSize: 40,
                                    fontFamily: 'Inter, sans-serif',
                                }}
                            >
                                All the <span style={{ fontSize: 42}}> Features </span> You Want.
                            </h1>
                            <h3
                                style={{
                                    fontSize: 24,
                                    position: 'relative',
                                    top: -17,
                                    fontFamily: 'Inter, sans-serif',
                                    fontWeight: 400
                                }}
                            >
                                Plus ones you never knew you Needed!
                            </h3>
                        </div>
                        <div id="rightTest"
                            style={{
                                margin: 'auto',
                                height: 400,
                                width: '40%',
                                paddingTop: 70
                            }}
                        >
                            {info.map((pair) => {
                                return(
                                    <FeatureCard key={pair.id} pair={pair} />
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div
                    className='infoSection'
                    style={{
                        width: '102%',
                        height: 250,
                        position: 'relative',
                        top: 100,
                        left: '-1%',
                        color: 'white',
                        backgroundColor: '#1F1F1F',
                        
                    }}
                >
                    <BottomLinks />
                </div>
            </div>
            <BarNav />
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
        </div>
    )
}

function FeatureCard(props) {
    return(
        <div
            style={{
                display: 'flex',
                height: 120,
                width: '100%',
                justifyContent: 'left',
                textAlign: 'left'
            }}
        >
            <img src={props.pair.icon} height="80px" width="80px" alt="Array Assistant Card"/>
            <div
                style={{
                    marginLeft: 20,
                    marginTop: 0
                }}
            >
                <h2 
                    style={{
                        width: '70%',
                        position: 'relative',
                        left: 0,
                        top: -10,
                        fontFamily: 'Inter Tight, sans-serif'
                    }}
                >
                    {props.pair.title}
                </h2>
                <h4
                    style={{
                        position: 'relative',
                        top: -20,
                        fontFamily: 'Inter, sans-serif'
                    }}
                >
                    {props.pair.subtitle}
                </h4>
            </div>
        </div>
    )
}