import React from 'react';
import BarNav from './BarComponent';
import BottomLinks from './BottomLinks';
import { solutions } from './data/solutionData.js';
import { useNavigate } from 'react-router-dom';
import './Solutions.css';
import { Helmet } from 'react-helmet';

import RightArrow from './Icons/RightArrow.png';
import Graphic from './media/graphic3.png';

export default function Solutions() {
    const navigate = useNavigate();

    return (
        <div id="solutionsRoot" >
            <Helmet>
                <html lang="en" />
                <title>Solutions</title>
                <link rel="canonical" href="http://arrayassistant.ai/solutions" />
                <meta name="description" content="See how our AI products apply to your industry." />
                <meta name="keywords" content="ai solutions, arificial intelligence for work, ai for business, chat gpt at work, ai tools" />
            </Helmet>
            <div id="solutionsHeadContainer">
                <div id="solutionsHeadText">
                    <p id="solutionsSection">
                        Solutions
                    </p>
                    <p id="solutionsTitle">
                        Help boost your team's productivity with Array Assistant
                    </p>
                    <button
                        id="signUpTodaySolutions"
                        onClick={() => {
                            navigate("/createAccount");
                        }}
                    >
                        <h5
                            style={{
                                position: 'relative',
                                top: -18,
                                fontSize: 20,
                                marginRight: 7,
                                marginLeft: 8,
                                color: 'black',
                                fontFamily: 'Inter Tight, sans-serif',
                                fontWeight: 800
                            }}
                        >
                            Join Today 
                        </h5>
                        <img src={RightArrow} alt="icon" height="30" width="50" id="arrow"
                            style={{ 
                                position: 'relative', 
                                top: 16,
                            }}
                        />
                    </button>
                </div>
                <div id="solutionsHeadGraphic">
                    <img src={Graphic} height="500" width="525" alt="Team Solutions" />
                </div>
            </div>
            <div id="solutionsMainContainer">
                <div id="solutionBufferZone">
                    <p>|</p>
                </div>
                <div id="cardSectionSolutions">
                    {solutions.map((solution) => {
                        return(
                            <CardRight solution={solution} key={solution.id} nav={navigate}/>
                        )
                    })}
                    <div id="solutionsBottomCap" >

                    </div>
                </div>
            </div>
            <div
                className='infoSection'
                style={{
                    width: '102%',
                    height: 250,
                    position: 'relative',
                    top: 100,
                    left: '-1%',
                    color: 'white',
                    backgroundColor: '#1F1F1F',
                    
                }}
            >
                <BottomLinks />
            </div>
            <BarNav />
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
        </div>
    )
}

const CardRight = props => {
    return(
        <div className='cardHolder'>
            <div id="industryRight">
                <p id="industryRightText">
                    {props.solution.industry}
                </p>
            </div>
            <div id="solutionsRight">
                <p>
                    {props.solution.solutions}
                </p>
            </div>
        </div>
    )
}

