import React, { useState, useEffect, useCallback } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import axios from 'axios';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../auth/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore'

const STRIPE_LIVE = "pk_live_51MZTBGLeql5JZZEeKvmafxHFF9wTyqk0oGe3JpGX9umB8ekkMUHOnFIjxlT3jOuuRInzQLYQdrwNecTbc3QWraLr00BlnWfcjL"
//const STRIPE_LIVE_TEST = "pk_test_51MZTBGLeql5JZZEePBXMl6f49nwS9LTvgaKOxoWesTGvNbEjq2t6VHWcG8HmneuBUzCMioMDagAEmc1WCfrz3cLE00DV4Q5ikd"
const stripePromise = loadStripe(STRIPE_LIVE);


export default function StripeContainer(type, trial) {
    const [options, setOptions] = useState({});
    const [ready, setReady] = useState(false);
    const [header, setHeader] = useState();
    const [price, setPrice] = useState();
    const [prevPrice, setPrevPrice] = useState(null);
    const [prevToggle, setPrevToggle] = useState(false);
    const [error, setError] = useState();

    const search = new URLSearchParams(window.location.search);
    
    const tier = type.type;
    const trialNew = type.trial;

    useEffect(() => {
        const error = search.get('error');
        if(error) {
            setError(error);
        }

        onAuthStateChanged(auth, async (user) => {
            let payID;
            switch(tier){
                case "0":
                    setHeader("Personal");
                    setPrice("Free");
                    setPrevPrice("$4.99");
                    setPrevToggle(true);
                    payID = 5;
                    break;
                case "1":
                    setHeader("Professional");
                    setPrice("$14.99 ");
                    payID = 1;
                    break;
                case "2":
                    setHeader("Enterprise");
                    setPrice("$49.99");
                    payID = 2;
                    break;
                default:
                    console.log("No Type Passed -- Needs Type [0-2]");
                    break;
            }

            if(user){
                const docRef = doc(db, "customers", user.uid);
                let data = await getDoc(docRef);

                if(data.exists()){
                    const parsed = data.data();
                    await axios.post('https://aiserver.herokuapp.com/createSetup', {
                        customer: parsed.customerId.id
                    })
                        .then((response) => {
                            setOptions({
                                clientSecret: response.data.clientSecret
                            })
                            setReady(true);
                        })
                        .catch((err) => {
                            console.log({ message: err.message });
                        });
                }
            }
        });
    }, []);

    

    if(!ready){
        return(
            <div
                style={{
                    height: 500,
                }}
            >
                <h2>
                    Loading...
                </h2>
            </div>
        )
    }
    
    return(
        <div
            style={{
                position: 'relative',
                left: '30%',
                marginBottom: 50,
            }}
        >
            <div
                style={{
                    width: '40%',
                    border: '1px solid white',
                    display: 'flex',
                    flexDirection: 'column',
                    background: 'black',
                    padding: 20,
                    borderRadius: 5,
                }}
            >
                <h2
                    style={{
                        color: 'white',
                        textAlign: 'left',
                        marginLeft: 15,
                        marginRight: 15,
                        marginBottom: 0, 
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 300,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    {header} <div><span style={{fontFamily: 'Inter Tight, sans-serif', fontWeight: 500}}> {price} { prevToggle ? (<><s style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,}} >{prevPrice}</s></>) : "" } <span style={{fontSize: 12}}>/Monthly</span></span></div>
                </h2>
                { trialNew ? (
                    <h3
                    style={{
                        textAlign: 'left',
                        color: 'white',
                        paddingLeft: 20,
                        fontFamily: 'Inter, sans-serif',
                    }}
                >
                    Start your 7 day Free Trial Today!
                </h3>
                ) : (
                    <h3
                    style={{
                        textAlign: 'left',
                        color: 'white',
                        paddingLeft: 20,
                        height: 10,
                        marginTop: 0,
                        marginBottom: 0
                    }}
                >
                    
                </h3>) }
                
                <Elements 
                    stripe={stripePromise}
                    options={options}
                >
                    <PaymentForm type={tier}/>
                </Elements>
            </div>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
        </div>
    )
}