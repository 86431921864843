import React from 'react';
import BarNav from '../BarComponent';
import BlogPost from './BlogPost';
import { useParams } from 'react-router-dom';
import { posts } from '../data/blogData';
import './BlogPostArch.css';

export default function BlogPostArch() {
    const { slug } = useParams();
    console.log("Slug: " + slug)

    const post = posts.find(post => post.slug === slug)
    console.log("Post Title: " + post.title)

    return (
        <div>
            <BarNav />
            <BlogPost post={post}/>
        </div>
    )
}
