import React, { useState, useCallback } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../auth/firebase';
import Logo from './Icons/Logo2.jpg';
import { useNavigate } from 'react-router-dom';
import BottomLinks from './BottomLinks';

export default function PassReset() {
    const [sent, setSent] = useState(false);
    const [email, setEmail] = useState();

    const navigate = useNavigate();

    const eHandle = useCallback((e) => {
        setEmail(e.target.value);
    })

    const sendEmail = () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setSent(true);
            })
            .catch((err) => {
                console.log({ message: err.message })
            });
    }

    return (
        <div>
            <div className="headerContainer" >
                <img src={Logo} alt="Array Assistant Logo" height="35px" width="35px" className="siteLogoCreate" onClick={() => {navigate("/")}} />
                <h3 className="siteTitle" onClick={() => {navigate("/")}} style={{ fontFamily: 'Inter Tight, sans-serif', fontWeight: 400}}>
                    <span style={{color: '#09CB62'}}>Array</span>Assistant
                </h3>
            </div>
            { !sent ? (
                <div className="googleAuthDiv1" >
                    <p className="emailTitle">
                        Enter Email Below:
                    </p>
                    <input type="text" className="email" onChange={(text) => eHandle(text)} />
                    <button
                        className="buttonInSign"
                        style={{
                            marginTop: 15
                        }}
                        onClick={() => {
                            sendEmail();
                        }}
                    >
                        Send Reset Email
                    </button>
                </div>
            ) : (
                <div className="googleAuthDiv1" >
                    <p>
                        A reset link has been sent to your email.
                    </p>
                    <button className="buttonInSign"
                        onClick={() => {
                            navigate("/signIn")
                        }}
                    >
                        Return to Log In
                    </button>
                </div>
            )}
            <div
                className='infoSection'
                style={{
                    width: "102%",
                    height: 250,
                    position: 'absolute',
                    top: '97vh',
                    left: "-1%",
                    margin: 0,
                    color: 'white',
                    backgroundColor: '#1F1F1F'
                }}
            >
                <BottomLinks />
            </div>
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
        </div>
    )
}
