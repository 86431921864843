import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Logo from './Icons/Logo2.jpg';

export default function CancelConfirmation() {
    const navigate = useNavigate();

    return (
        <div>
            <div id="headerContainer" >
                <img src={Logo} alt="Array Assistant Logo" height="35px" width="35px" id="siteLogoCreate" onClick={() => {navigate("/")}} />
                <h3 id="siteTitle" onClick={() => {navigate("/")}} style={{ fontFamily: 'Inter Tight, sans-serif', fontWeight: 300}}>
                    <span style={{color: '#09CB62'}}>Array</span>Assistant
                </h3>
            </div>
            <p
                style={{
                    fontFamily: 'Inter, sans-serif',
                    fontSize: 18,
                    fontWeight: 300,
                    marginLeft: 75,
                }}
            >
                Your subscription has been cancelled.
            </p>
            <button
                style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 500,
                    fontSize: 17,
                    height: 40,
                    width: 200,
                    borderRadius: 8,
                    border: '1px solid grey',
                    background: 'white',
                    cursor: 'pointer',
                    marginLeft: 75,
                }}
                onClick={() => {navigate("/")}}
            >
                Back to main Website
            </button>
            {
                //consider adding a button to continue subscription
            }
        </div>
    )
}
