export const info = [
    {
        "id": 1,
        "title": "Search Engine",
        "description": "Creating a search engine based around AI and Privacy",
        "link": "/dashboard/formulaTools/dev/search",
        "beta": true,
    },
    {
        "id": 2,
        "title": "NoteGPT",
        "description": "A notebook, but with an AI assistant to help",
        "link": "/dashboard/formulatools/dev",
        "beta": true,
    },
    {
        "id": 3,
        "title": "Interview Partner",
        "description": "Practice for Interviews with the help of AI",
        "link": "/dashboard/formulatools/dev",
        "beta": true,
    },
]