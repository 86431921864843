import React, { useState, useCallback } from 'react';
import './FormulaTools.css';
import { info } from './data/devProjects';
import { useNavigate } from 'react-router-dom';

export default function DevCenter() {
    const navigate = useNavigate();
    const [access, setAccess] = useState(false);
    const [code, setCode] = useState();

    const iHandle = useCallback((e) => {
        setCode(e.target.value);
    })

    const checkAccess = useCallback(() => {
        if(code == "1203") {
            setAccess(true);
        }
    })

    if(!access){
        return (
            <div className='formulaToolsMain'>
                <div className='toolCardMain' 
                    style={{
                        cursor: 'auto',
                        marginLeft: 25,
                    }}
                > 
                    <p>
                        Enter Code:
                    </p>
                    <input type="password" onChange={(text) => { iHandle(text); }} 
                        style={{
                            width: '90%',
                            height: 30,
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 500, 
                            borderRadius: 5, 
                            fontSize: 17, 
                            backgroundColor: '#333333',
                            border: '1px solid white',
                            marginBottom: 15,
                            color: 'white',
                            marginLeft: '4.75%',
                            cursor: 'pointer'
                        }}
                    />
                    <button
                        onClick={() => {
                            checkAccess();
                        }}
                        style={{
                            width: '80%',
                            height: 40,
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 500, 
                            borderRadius: 8, 
                            fontSize: 17, 
                            backgroundColor: '#333333',
                            border: '1px solid white',
                            marginBottom: 15,
                            color: 'white',
                            marginLeft: '10%',
                            cursor: 'pointer'
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        )
    }

    if(access){
        return (
            <div className='formulaToolsMain' >
                <div className='toolsContainer' >
                    {info.map((tool) => {
                        return(
                            <ToolCard tier={tool} key={tool.id} nav={navigate} />
                        )
                    })}
                </div>
            </div>
        )
    }
}

const ToolCard = props => {
    return(
        <div className='toolCardMain'
            onClick={() => {
                props.nav(`${props.tier.link}`)
            }}
        > 
            <p
                style={{
                    fontFamily: 'Inter Tight, sans-serif',
                    fontWeight: 500,
                    fontSize: 27,
                    marginLeft: 5,
                    marginTop: 15
                }}
            >
                {props.tier.title} { props.tier.beta ? (<span style={{color: 'grey'}}>- Beta</span>) : ("")}
            </p>
            <p
                style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 300,
                    fontSize: 19,
                    textAlign: 'right',
                    height: '3em',
                    margin: 0,
                }}
            >
                {props.tier.description}
            </p>
        </div>
    )
}
