import React, { useState, useEffect } from 'react';
import { useStripe, loadStripe } from '@stripe/react-stripe-js';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { auth, db } from '../auth/firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import Logo from './Icons/Logo2.jpg';
import BottomLinks from './BottomLinks';
import { gtag } from 'ga-gtag';
import axios from 'axios';
import './CheckStatus.css';

export default function CheckStatus() {
    const [checkout, setCheckout] = useState();
    const [confirmationStatus, setConfirmationStatus] = useState(false);

    const navigate = useNavigate();
    const stripe = useStripe();

    console.log(stripe)
    

    useEffect(() => {
        gtag('event', 'conversion', {'send_to': 'AW-10990044359/Vz35CIu9sb8YEMeJu_go'});

        const search = new URLSearchParams(window.location.search);
        const type = search.get('type');
        const code = search.get('code');
        //const clientSecret = search.get('payment_intent_client_secret');
        const setupSecret = search.get('setup_intent_client_secret');

        console.log("The secret is: " + setupSecret);

        if(stripe) {
            let requests = 50;

            switch(type){
                case "0":
                    setCheckout("/personal");
                    requests = 100;
                    break;
                case "1":
                    setCheckout("/professional");
                    requests = 1000;
                    break;
                case "2":
                    setCheckout("/enterprise");
                    requests = 1000000;
                    break;
                default:
                    requests = 0;
                    break;
            }

            stripe.retrieveSetupIntent(setupSecret)
                .then(async ({setupIntent}) => {
                    switch(setupIntent.status) {
                        case 'processing':
                            console.log("Still Processing...");
                            break;
                        case 'succeeded':
                            onAuthStateChanged(auth, async (user) => {
                                if (user) {
                                    console.log(setupIntent)
                                    await axios.post("https://aiserver.herokuapp.com/createSubscription", {
                                        intent_id: setupIntent.id,
                                        uid: user.uid,
                                        subscription_type: type,
                                        code: code,
                                        sub_type: type,
                                    })
                                        .then(async (response) => {
                                            const docRef = doc(db, "customers", user.uid);

                                            const rawData = await getDoc(docRef);
                                            const data = rawData.data();
    
                                            await updateDoc(docRef, {
                                                userSubscription: true,
                                                requests: requests,
                                                subscriptionType: type,
                                                subscriptionId: response.data.id,
                                            });
    
                                            navigate("/dashboard");
                                    })
                                    .catch((err) => {
                                        console.log({ message: err.message })
                                    })
                                }
                            })
                            
                    }
                })
        } else {
            console.log("Stripe not yet loaded in");
        }
    }, [stripe]);

    return(
        <div>
            <div className="headerContainer" >
                <img src={Logo} alt="Array Assistant Logo" height="35px" width="35px" className="siteLogoCreate" onClick={() => {navigate("/")}} />
                <h3 className="siteTitle" onClick={() => {navigate("/")}} style={{ fontFamily: 'Inter Tight, sans-serif', fontWeight: 400}}>
                    <span style={{color: '#09CB62'}}>Array</span>Assistant
                </h3>
            </div>
            <p id="thanksTitle" >
                Thank you for joining Array Assistant!
            </p>
            <p id="systemNudgeTitle">
                If you are not redirected within a few seconds, click the button below:
            </p>
            <button id="systemNudge" >
                Reload
            </button>
            <div
                className='infoSection'
                style={{
                    width: "102%",
                    height: 250,
                    position: 'absolute',
                    top: '97vh',
                    left: "-1%",
                    margin: 0,
                    color: 'white',
                    backgroundColor: '#1F1F1F'
                }}
            >
                <BottomLinks />
            </div>
        </div>
    );
}
