import React, { useCallback, useState } from 'react';
import axios from 'axios';
import './searchBeta.css';

//This is the module for the test 3.5 - 4 search engine
export default function SearchBeta() {
  const [search, setSearch] = useState();
  const [result, setResult] = useState();

  const inputHandle = useCallback((e) => {
    setSearch(e.target.value);
  })

  const fetchSearch = useCallback(async () => {
    setResult("Thinking...")
    await axios.post("https://aiserver.herokuapp.com/search", {
      search: search
    })
      .then((response) => {
        console.log(response);
        const message = response.data.message.content;
        setResult(message);
      })
      .catch((err) => {
        console.log({ message: err.message })
      })
  })

  return (
    <div className='searchMain'>
      <div className='searchBarContainer'>
        <input type='text' id="searchBar" placeholder='Search Anything...' onChange={(text) => {inputHandle(text)}} />
        <button id="searchButton" onClick={() => {fetchSearch();}} >
          Search
        </button>
      </div>
      <div className='resultContainer' >
        <p id="textResult" >
          {result}
        </p>
      </div>
    </div>
  )
}
