import React, { useEffect, useCallback, useState } from 'react';
import './formulaExp.css';
import axios from 'axios';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../auth/firebase';

export default function FormulaExp() {
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState();
    const [user, setUser] = useState();

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setUser(user.uid);
        } else {
          console.log("User does not exist.")
        }
      })
    }, [])
  
    const handleInputChange = useCallback((e) => {
      setInput(e.target.value);
    });
  
    const fetchCall = useCallback(async () => {
      setLoading(true);
      await axios.post("https://aiserver.herokuapp.com/exp", {
            equation: input,
            user: user
          })
            .then((response) => {
              let text = response.data.choices[0].text;
              let formattedText = text.split("\n\n").join("");
              setData(formattedText);
              setLoading(false);
            })
            .catch((err) => {
              console.log({ message: err.message })
              setLoading(false);
            });
    });
  
    return (
      <div className="formulaMain">
        <div className='mainContainer'>
            <div className='titleSection'>
                <h2 className='mainTitle'>
                    Formula Explainer
                </h2>
            </div>
            <div className='inputSection'>
                <h4 className='inputLabel'>
                    What formula do you need explained?
                </h4>
                <textarea 
                    type="text" 
                    placeholder="Enter the forumla here..." 
                    rows='4'
                    cols='50'
                    className='mainInput'
                    onChange={handleInputChange}
                />
            </div>
            <div className='submitExplainButtonDiv'>
                <button 
                    className='submitButton'
                    onClick={() => {
                      fetchCall();
                    }}
                    >
                    { loading ? "Loading..." : "Generate" }
                </button>
            </div>
            <div className='outputSection'>
                <h4 className='outputLabel'>
                    Formula Output:
                </h4>
                <textarea 
                    type="text" 
                    placeholder='Input something above!'
                    value={data}
                    rows='4'
                    cols='50'
                    className='mainExplainOutput'
                    readOnly
                    /> 
                </div>
        </div>
        <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet"/>
      </div>
)};
