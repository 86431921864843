import React, {useState, useEffect, useCallback} from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { auth } from '../auth/firebase';
import './sqlGenerator.css';


export default function SqlGenerator() {
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState();
    const [user, setUser] = useState();

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setUser(user.uid);
        } else {
          console.log("User does not exist.")
        }
      })
    }, [])
  
    const handleInputChange = useCallback((e) => {
      setInput(e.target.value);
    });

    const copyFunction = useCallback( async () => {
        navigator.clipboard.writeText(data);
    });
  
    const fetchCall = useCallback(async () => {
      setLoading(true);
      await axios.post("https://aiserver.herokuapp.com/sql", {
            input: input,
            user: user
          })
            .then((response) => {
              let text = response.data.choices[0].text;
              let formattedText = text.split("\n\n").join("");
              setData(formattedText);
              setLoading(false);
            })
            .catch((err) => {
              console.log({ message: err.message })
              setLoading(false);
            });
    });
  
    return (
      <div className="formulaMain">
        <div className='mainContainer'>
            <div className='titleSection'>
                <h2 className='mainTitle'>
                    SQL Generator
                </h2>
            </div>
            <div className='inputSection'>
                <h4 className='inputLabel'>
                    What should the SQL Query do?
                </h4>
                <textarea 
                    type="text" 
                    placeholder="Enter description here..." 
                    rows='4'
                    cols='50'
                    className='mainInput'
                    onChange={handleInputChange}
                />
            </div>
            <div className='submitExplainButtonDiv'>
                <button 
                    className='submitButton'
                    onClick={() => {
                      fetchCall();
                    }}
                    >
                    { loading ? "Loading..." : "Generate" }
                </button>
            </div>
            <div className='outputSection'>
                <h4 className='outputLabel'>
                    Query Output:
                </h4>
                <textarea 
                    type="text" 
                    placeholder='Input something above!'
                    value={data}
                    rows='4'
                    cols='50'
                    className='mainExplainOutput'
                    readOnly
                    /> 
                </div>
                <div>
                <button id="copyGenButton"
                  onClick={() => {
                    copyFunction()
                  }}
                >
                  Copy Query
                </button>
              </div>
        </div>
        <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet"/>
      </div>
    )
}
