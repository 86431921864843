import React, { useState } from 'react'
import './PersonalCheckout.css';
import StripeContainer from './StripeContainer.js';
import { auth } from '../auth/firebase';
import { Navigate } from 'react-router-dom';
import BottomLinks from './BottomLinks';

export default function EnterpriseCheckout() {
    const [trial, setTrial] = useState(false);

    return (
        <div id="personalCheckoutDiv">
            { auth.currentUser ? (
            <div
                style={{
                    textAlign: "center"
                }}
            >
                <h1>
                    Checkout
                </h1>
                <div>
                    <StripeContainer type="2" trial={trial} />
                </div>
            </div>
            ) : (
                <>
                    <Navigate to="/createAccount" />
                </>
            )}
            <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet"></link>
            <div
                className='infoSection'
                style={{
                    width: '102%',
                    height: 250,
                    position: 'relative',
                    top: '70px',
                    left: '-1%',
                    color: 'white',
                    backgroundColor: '#1F1F1F' 
                }}
            >
                <BottomLinks />
            </div>
        </div>
    )
}
