import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { BtmLink } from './BottomStyledLink.js';

export default function BottomLinks() {
    return(
        <div
            style={{
                height: 220,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
            }}
        >
            <div>
                <h5
                    style={{
                        height: 21,
                        width: '150px',
                        fontFamily: 'Inter Tight, sans-serif',
                        fontSize: 18,
                        fontWeight: 500,
                        position: 'relative',
                        left: 45,
                        top: 10,
                        borderBottom: '1px solid lightgrey',
                    }}
                >
                    Information:
                </h5>
                <BtmLink
                    to="/features"
                    style={{
                        position: 'relative',
                        left: 55,
                    }}
                >
                    Features<br/>
                </BtmLink>
                <BtmLink
                    to="/pricing"
                    style={{
                        position: 'relative',
                        top: 10,
                        left: 55,
                    }}
                >
                    Pricing<br/>
                </BtmLink>
                <BtmLink
                    to="/solutions"
                    style={{
                        position: 'relative',
                        top: 20,
                        left: 55,
                    }}
                >
                    Solutions<br/>
                </BtmLink>
            </div>
            <div
                style={{
                    marginLeft: 50,
                }}
            >
                <h5
                    style={{
                        height: 21,
                        width: '130px',
                        fontFamily: 'Inter Tight, sans-serif',
                        fontSize: 18,
                        fontWeight: 500,
                        position: 'relative',
                        left: 45,
                        top: 10,
                        borderBottom: '1px solid lightgrey',
                    }}
                >
                    Company:
                </h5>
                <BtmLink
                    to="/about"
                    style={{
                        position: 'relative',
                        left: 55,
                    }}
                >
                    About Us<br/>
                </BtmLink>
                <BtmLink
                    to="/contact"
                    style={{
                        position: 'relative',
                        top: 10,
                        left: 55,
                    }}
                >
                    Contact<br/>
                </BtmLink>
                <BtmLink
                    to="/legal"
                    style={{
                        position: 'relative',
                        top: 20,
                        left: 55,
                    }}
                >
                    Legal<br/>
                </BtmLink>
                <BtmLink
                    to="/climate"
                    style={{
                        position: 'relative',
                        top: 30,
                        left: 55,
                    }}
                >
                    Climate<br/>
                </BtmLink>
                <BtmLink
                    to="/affiliate"
                    style={{
                        position: 'relative',
                        top: 40,
                        left: 55,
                    }}
                >
                    Affiliates<br/>
                </BtmLink>
            </div>
            <Outlet />
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
        </div>
    )
}