export const info = [
    {
        "id": 1,
        "title": "PDF Genius",
        "description": "Have AI Answer your questions about any PDF!",
        "requestCost": 1,
        "link": "/dashboard/texttools/pdf",
        "beta": true,
    },
    {
        "id": 2,
        "title": "Problem Solver",
        "description": "Simple Solutions for Any Problem!",
        "link": "/dashboard/texttools/problem",
        "beta": false,
    },
    {
        "id": 3,
        "title": "Email Builder",
        "description": "Under Construction, Coming Soon!",
        "link": "/dashboard/texttools",
        "beta": true,
    },
    {
        "id": 4,
        "title": "Summary Creator",
        "description": "Under Construction, Coming Soon!",
        "link": "/dashboard/textools",
        "beta": true,
    },
    {
        "id": 5,
        "title": "Resume Checker",
        "description": "Under Construction, Coming Soon!",
        "link": "/dashboard/texttools",
        "beta": true,
    }
]

//Upload a PDF and get your questions answered