export const info = [
    {
        "id": 1,
        "title": "Formula Generator",
        "description": "Easily generate complex formulas for Sheets or Excel.",
        "link": "/dashboard/formulatools/formulagen",
        "beta": false,
    },
    {
        "id": 2,
        "title": "Formula Explainer",
        "description": "Get simple explanations for Sheets or Excel Formulas.",
        "link": "/dashboard/formulatools/formulaexp",
        "beta": false,
    },
    {
        "id": 3,
        "title": "SQL Designer",
        "description": "Use AI to Build Elegant SQL Queries!",
        "link": "/dashboard/formulatools/sqlGen",
        "beta": false,
    },
    {
        "id": 4,
        "title": "VBA Designer",
        "description": "Under Construction, Coming Soon!",
        "link": "/dashboard/formulatools",
        "beta": true,
    },
    {
        "id": 5,
        "title": "Excel Chatbot",
        "description": "Under Construction, Coming Soon!",
        "link": "/dashboard/formulatools",
        "beta": true,
    },
    {
        "id": 6,
        "title": "Spreadsheet Builder",
        "description": "Under Construction, Coming Soon!",
        "link": "/dashboard/formulatools",
        "beta": true,
    },
    {
        "id": 7,
        "title": "Dev Center",
        "description": "For Development and Prototype Testing",
        "link": "/dashboard/formulatools/dev",
        "beta": false,
    },
]