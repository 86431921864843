import Logo from '../media/logo.jpeg';

export const posts = [
    {
        "id": 0,
        "slug": "article-0",
        "img": Logo,
        "author": "Evan Frisbie",
        "date": "9/12/23",
        "title": "Using AA with MS Excel.",
        "preview": `This is the preview just to show you what 
                    the article may contain within it, for interested 
                    viewers!`,
        "content": `This article is just a test article to show that 
                    the blog posts can be displayed.`

    },
    {
        "id": 1,
        "slug": "article-1",
        "img": Logo,
        "author": "Evan Frisbie",
        "date": "9/13/23",
        "title": "How Array Assistant Works!",
        "content": `This article is just a test article to show that 
                    the blog posts can be displayed.`
    },
    {
        "id": 2,
        "slug": "article-2",
        "img": Logo,
        "author": "Evan Frisbie",
        "date": "9/14/23",
        "title": "Why are spreadsheets important?",
        "content": `This article is just a test article to show that 
                    the blog posts can be displayed.`
    }
]