export const solutions = [
    {
        "id": 1,
        "industry": "Investment Banking",
        "solutions": "Quickly build, clean, and analyze lists of potential clients with the List Building and Formula Generation features."
    },
    {
        "id": 2,
        "industry": "Private Equity",
        "solutions": "Expedite your deal sourcing and market mapping workflows with AI."
    },
    {
        "id": 3,
        "industry": "Accounting Firms",
        "solutions": "Manage financial statements quicker with our Formula and Data management tools."
    },
    {
        "id": 4,
        "industry": "Venture Capital",
        "solutions": "Use our data management tools to better analyze a company's financials."
    },
    {
        "id": 5,
        "industry": "Consulting",
        "solutions": "Upgrade your teams productivity with the newest AI tools."
    },
    {
        "id": 6,
        "industry": "Real Estate",
        "solutions": "Organize client data and analyze trends in the Real Estate market."
    },
    {
        "id": 7,
        "industry": "Insurance",
        "solutions": "Manage claims while working faster with the lastest AI."
    },
    {
        "id": 8,
        "industry": "Professional",
        "solutions": "Whether you are a business owner or an executive manager, our new AI tools can help you boost your productivity."
    }
]