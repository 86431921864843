import React, { useState, useEffect } from 'react';
import StripeContainer from './StripeContainer.js';
import { auth } from '../auth/firebase';
import { Navigate, useLocation } from 'react-router-dom';
import BottomLinks from './BottomLinks';

export default function ProfessionalCheckout() {
    return (
        <div id="personalCheckoutDiv">
                { auth.currentUser ? (
                <div
                    style={{
                        textAlign: "center"
                    }}
                >
                    <h1>
                        Checkout
                    </h1>
                    <div>
                        <StripeContainer type="1"/>
                    </div>
                </div>
                ) : (
                    <>
                        <Navigate to="/createAccount" />
                    </>
                )}
                <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet"></link>
                <div
                    className='infoSection'
                    style={{
                        width: '102%',
                        height: 250,
                        position: 'relative',
                        top: '70px',
                        left: '-1%',
                        color: 'white',
                        backgroundColor: '#1F1F1F' 
                    }}
                >
                    <BottomLinks />
                </div>
            </div>
    )
}
