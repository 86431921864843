import React from 'react';
import './FormulaTools.css';
import { info } from './data/formulaToolData';
import { useNavigate } from 'react-router-dom';

export default function FormulaTools() {
    const navigate = useNavigate();

    return (
        <div className='formulaToolsMain' >
            <div className='toolsContainer' >
                {info.map((tool) => {
                    return(
                        <ToolCard tier={tool} key={tool.id} nav={navigate} />
                    )
                })}
            </div>
        </div>
    )
}

const ToolCard = props => {
    return(
        <div className='toolCardMain'
            onClick={() => {
                props.nav(`${props.tier.link}`)
            }}
        > 
            <p
                style={{
                    fontFamily: 'Inter Tight, sans-serif',
                    fontWeight: 500,
                    fontSize: 27,
                    marginLeft: 5,
                    marginTop: 15
                }}
            >
                {props.tier.title} { props.tier.beta ? (<span style={{color: 'grey'}}>- Beta</span>) : ("")}
            </p>
            <p
                style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 300,
                    fontSize: 19,
                    textAlign: 'right',
                    height: '3em',
                    margin: 0,
                }}
            >
                {props.tier.description}
            </p>
        </div>
    )
}
