import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckStatus  from './CheckStatus';

const PUBLIC_KEY = "pk_live_51MZTBGLeql5JZZEeKvmafxHFF9wTyqk0oGe3JpGX9umB8ekkMUHOnFIjxlT3jOuuRInzQLYQdrwNecTbc3QWraLr00BlnWfcjL";
const stripePromise = loadStripe(PUBLIC_KEY);

export default function PaymentCheck() {
    return (
        <div>
            <Elements
                stripe={stripePromise}
            >
                <CheckStatus />
            </Elements>
        </div>
    )
}
