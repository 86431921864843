import React from 'react';
import './ComingSoon.css';

export default function ComingSoon() {
  return (
    <div className='mainDev'>
        <h1 id="csHeader"
          style={{
            fontFamily: 'Inter Tight, sans-serif',
            fontWeight: 500,
          }}
        >
            Coming Soon!
        </h1>
        <h3
          style={{
            fontFamily: 'Inter, sans-serif',
            fontWeight: 300,
            width: '70%',
            marginLeft: '15%',
          }}
        >
            We are currently still working on this feature! However, we can assure
            you that it will be released and ready for you to use very soon!
        </h3>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
        <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
    </div>
  )
}
