import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BrowserView, MobileView } from 'react-device-detect';
import './index.css';

import Home from './pages/Home';
import Layout from './pages/Layout';
import FormulaTools from './pages/FormulaTools';
import FormulaExp from './pages/formulaExp';
import FormulaGen from './pages/formulaGen';
import FormulaBank from './pages/formulaBank';
import TextTools from './pages/TextTools';
import ProblemSolver from './pages/problemSolver';
import PDFGenius from './pages/PDFGenius';
import Extensions from './pages/Extensions';
import Templates from './pages/Templates';
import Pricing from './pages/Pricing';
import Docs from './pages/Docs';
import Account from './pages/Account';
import HomeFront from './pagesFront/Home';
import Features from './pagesFront/Features';
import PricingFront from './pagesFront/PricingFront';
import Blog from './pagesFront/Blog';
import About from './pagesFront/About';
import Legal from './pagesFront/Legal';
import Landing from './pagesFront/Landing';
import CreateAccount from './pagesFront/CreateAccount';
import PersonalCheckout from './pagesFront/PersonalCheckout';
import ProfessionalCheckout from './pagesFront/ProfessionalCheckout';
import EnterpriseCheckout from './pagesFront/EnterpriseCheckout';
import PaymentCheck from './pagesFront/PaymentCheck';
import SignIn from './pagesFront/SignIn';
import PaymentWall from './pagesFront/PaymentWall';
import CancelPage from './pagesFront/CancelPage';
import ComingSoon from './pages/ComingSoon';
import Solutions from './pagesFront/Solutions';
import PassReset from './pagesFront/PassReset';
import Upgrade from './pages/Upgrade';
import HomeMobile from './pagesFront/HomeMobile';
import ContactUs from './pagesFront/ContactUs';
import ClimateInformation from './pagesFront/ClimateInformation';
import TestRoute from './pages/TestRoute';
import SearchBeta from './pages/SearchBeta';
import AffiliateProgram from './pagesFront/AffiliateProgram';
import DevCenter from './pages/devCenter';
import CancelConfirmation from './pagesFront/CancelConfirmation';
import BlogContainer from './pagesFront/BlogContainer';
import BlogPostArch from './pagesFront/BlogPost/BlogPostArch';
import SqlGenerator from './pages/sqlGenerator';
import VBAGen from './pages/vbaGen';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <>
    <BrowserView>
      <BrowserRouter className="router">
          <Routes>
            <Route index element={<HomeFront />} />
            <Route path="/" element={<HomeFront />} />
            <Route path="/plugin" element={<Features /> } />
            <Route path="/pricing" element={<PricingFront /> } />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/createAccount" element={<CreateAccount />} />
            <Route path="/signIn" element={<SignIn />} />
            <Route path="/products" element={<PaymentWall />} />
            <Route path="/blog" element={<BlogContainer />} />
              <Route index elemeent={<Blog />} />
              <Route path="/blog/:slug" element={<BlogPostArch />} />
            <Route path="/personal" element={<PersonalCheckout />} />
            <Route path="/professional" element={<ProfessionalCheckout />} />
            <Route path="/enterprise" element={<EnterpriseCheckout />} />
            <Route path="/check" element={<PaymentCheck />} />
            <Route path="/cancel" element={<CancelPage />} />
            <Route path="/confirm" element={<CancelConfirmation />} />
            <Route path="/about" element={<About />} />
            <Route path="/reset" element={<PassReset />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/contact" element={ <ContactUs />} />
            <Route path="/climate" element={ <ClimateInformation />} />
            <Route path="/affiliate" element={ <AffiliateProgram />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/dashboard" element={<Layout />} >
              <Route index element={<Home />} />
              <Route path="/dashboard/" element={<Home />} />
              <Route path="/dashboard/formulatools" element={<FormulaTools />} />
              <Route path="/dashboard/formulatools/formulaGen" element={<FormulaGen />} />
              <Route path="/dashboard/formulatools/formulaExp" element={<FormulaExp />} />
              <Route path="/dashboard/formulatools/sqlGen" element={<SqlGenerator />} /> 
              <Route path="/dashboard/formulatools/vbaGen" element={<VBAGen />} />
              <Route path="/dashboard/formulatools/dev" element={<DevCenter />} />
              <Route path="/dashboard/formulatools/dev/search" element={<SearchBeta />} />
              <Route path="/dashboard/texttools" element={<TextTools />} />
              <Route path="/dashboard/texttools/pdf" element={<PDFGenius />} />
              <Route path="/dashboard/texttools/problem" element={<ProblemSolver />} />
              <Route path="/dashboard/formulaBank" element={<FormulaBank />} />
              <Route path="/dashboard/extensions" element={<Extensions />} />
              <Route path="/dashboard/templates" element={<Templates />} />
              <Route path="/dashboard/pricing" element={<Pricing />} />
              <Route path="/dashboard/docs" element={<Docs />} />
              <Route path="/dashboard/account" element={<Account />} />
              <Route path="/dashboard/inDev" element={<ComingSoon />} />
              <Route path="/dashboard/upgrade" element={<Upgrade />} />
              <Route path="/dashboard/test" element={<TestRoute />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </BrowserView>
      <MobileView>
        <HomeMobile />
      </MobileView>
    </>
);