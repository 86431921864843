import React from 'react';
import { TitleLink, SubLink, SignLink } from './StyledLink';
import { Outlet } from 'react-router-dom';
import './BarComponent.css';
import { useNavigate } from 'react-router-dom';

import Logo2 from './Icons/Logo2.jpg'

export default function BarNav() {

    const navigate = useNavigate();

    return(
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                height: 100,
                color: 'black',
                position: 'fixed',
                top: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                background: 'white',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    width: 310,
                }}
            >
                <img src={Logo2} height="46px" width="53px" alt="logo" onClick={() => {navigate("/")}}
                    style={{
                        position: 'absolute',
                        top: 20,
                        left: 33,
                        cursor: 'pointer',
                    }}
                />
                <TitleLink
                    to='/'
                    className='frontPage'
                    style={{
                        position: 'relative',
                        left: 98,
                        top: 5,
                        fontFamily: 'Inter',
                    }}
                >
                    <h1
                        style={{
                            fontWeight: '500',
                            letterSpacing: 0,
                            color: '#09CB62',
                            fontFamily: 'Inter Tight, sans-serif'
                        }}
                    >
                        Array<span style={{color: 'black'}}>Assistant</span>
                    </h1>
                </TitleLink>
            </div>
            <div>
                <div 
                    className='subDiv'
                    style={{
                        height: 50,
                        width: '30%',
                        color: 'black',
                        marginTop: 35,
                        display: 'inline-flex',
                    }}
                >
                        <div
                            style={{
                                position: 'relative',
                                top: -18,
                                width: 340,
                                display: 'flex',
                            }}
                        >
                            <SubLink
                                to='/plugin'
                                className='testPage'
                            >
                                <h3 
                                    className='testTitle'
                                    style={{
                                        width: 90,
                                        fontWeight: 300,
                                        fontSize: 20
                                    }}
                                >
                                    Plugin
                                </h3>
                            </SubLink>
                            <SubLink
                                to='/solutions'
                                className='testPage'
                            >
                                <h3 
                                    className='testTitle'
                                    style={{
                                        width: 110,
                                        fontWeight: 300,
                                        fontSize: 20
                                    }}
                                >
                                    Solutions
                                </h3>
                            </SubLink>
                            <SubLink
                                to="/pricing"
                            >
                                <h3
                                    style={{
                                        width: 100,
                                        marginLeft: 15,
                                        fontWeight: 300,
                                        fontSize: 20
                                    }}
                                >
                                    Pricing
                                </h3>
                            </SubLink>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div id="userGroup" >
                            <SubLink to="/signIn">
                                <h3
                                    style={{
                                        width: 88,
                                        marginTop: 4,
                                        fontWeight: 300,
                                        fontSize: 20
                                    }}
                                >
                                    Log In
                                </h3>
                            </SubLink>
                            <div
                                id="signUp"
                            >
                                <SignLink
                                    to="/createAccount"
                                    className='testPage'
                                >
                                    <h3
                                        className='testTitle'
                                        style={{
                                            width: '130px',
                                            height: '40px',
                                            position: 'relative',
                                            top: -14,
                                            marginLeft: -6,
                                            fontWeight: 300,
                                            fontFamily: 'Inter Tight, sans-serif '
                                        }}
                                    >
                                        Sign Up
                                    </h3>
                                </SignLink>
                            </div>
                        </div>
                        <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
                        <link rel="preconnect" href="https://fonts.googleapis.com" />
                        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin ></link>
                    </div>
                <Outlet />
            </div>
        </div>
    )
}

/*

placeholder div for fixed element usage

<div
                style={{
                    width: '100%',
                    height: 100,
                    position: 'relative',
                    top: 0,
                    left: 0,
                }}
            >


*/

/*
Blog Route Removed For Version 1

<SubLink
    to='/'
>
    <h3 
        className='testTitle'
        style={{
            width: 110,
            marginLeft: -5,
            fontWeight: 300,
            fontSize: 20
        }}
    >
        Blog
    </h3>
</SubLink>

*/