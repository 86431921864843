import React, { useState, useCallback, useEffect } from 'react';
import './SignIn.css';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { auth, db } from '../auth/firebase.js';
import BottomLinks from './BottomLinks';
import { getDoc, doc } from 'firebase/firestore';
import { SwitchLink } from './StyledLink';
import Logo from './Icons/Logo2.jpg';
import { Helmet } from 'react-helmet';

export default function SignIn() {
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [secRe, setSecRe] = useState(false);
    const [errorSignIn, setErrorSignIn] = useState(false);

    const navigate = useNavigate();

    const eHandle = useCallback((e) => {
        setEmail(e.target.value);
    });

    const pHandle = useCallback((e) => {
        setPass(e.target.value);
    });  

    const signIn = (email, password) => {
        setErrorSignIn(false)
        signInWithEmailAndPassword(auth, email, password)
            .then(async () => {
                const customerGet = await getDoc(doc(db, "customers", auth.currentUser.uid));
                const customerData = customerGet.data();
                const sub = customerData.userSubscription;
                if (sub === "Paid") {
                    setSecRe(true);
                    console.log("true");
                }
            })
            .then(() => {
                setErrorSignIn(false);
                setRedirect(true);
            })
            .catch((err) => {
                console.log({ message: err.message });
                setErrorSignIn(true);
            });
    };

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const docRef = doc(db, "customers", user.uid);
                const data = await getDoc(docRef);

                const parsed = data.data();

                console.log(parsed);
                const sub = parsed.userSubscription;

                if(sub){
                    navigate('/dashboard')
                } else if (!sub) {
                    navigate('/products')
                } else {
                    console.log("No User Signed In")
                }
            } 
        });
    },[]);
    
    return(
        <div className="signInDiv" style={{maxWidth: '1920px', margin: 'auto'}}>
            <Helmet>
                <html lang="en" />
                <title>Sign In</title>
                <meta name="description" content="Sign In to your Array Assistant Account." />
                <meta name="keywords" content="excel, sign in for ai formula creator, ai tools assistant" />
            </Helmet>
            { !redirect ? (
                <>
                    <div className="headerContainer" >
                        <img src={Logo} alt="Array Assistant Logo" height="35px" width="35px" className="siteLogoCreate" onClick={() => {navigate("/")}} />
                        <h3 className="siteTitle" onClick={() => {navigate("/")}} style={{ fontFamily: 'Inter Tight, sans-serif', fontWeight: 400}}>
                            <span style={{color: '#09CB62'}}>Array</span>Assistant
                        </h3>
                    </div>
                    <div className='googleAuthDiv1' >
                        <h1 className="signInTitle">
                            Sign In
                        </h1>
                        <h4 className='emailTitle'>
                            Email:
                        </h4>
                        <input 
                            type="text" 
                            className='email'
                            onChange={eHandle}
                            placeholder='Email address'
                            autoFocus='autofocus'
                        />
                        
                        <h4 className='passTitle'>
                            Password:
                        </h4>
                        <input 
                            type="password" 
                            id='signInPass'
                            onChange={pHandle}
                            placeholder='Password'
                            
                        />
                        <p
                            id="passResetLink"
                            onClick={() => {
                                navigate("/reset");
                            }}
                        >
                            Forgot Password?
                        </p>
                        <div>
                            <button 
                                className='buttonInSign'
                                onClick={() => signIn(email, pass)}
                            >
                                Sign In
                            </button>
                        </div>
                    </div>
                    <div
                        style={{
                            placeItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                // position: 'relative' ,
                                margin: 'auto',
                                placeContent: 'center',
                                display: 'flex',
                                textAlign: 'center',
                                marginTop: 20,
                            }}
                        >
                            <p className="preSwitch"> Don't Have an Account?&nbsp; </p>
                            <SwitchLink
                                to="/createAccount"
                                style={{
                                    placeItems: 'center'
                                }}
                            >
                                <p> Sign Up </p>
                            </SwitchLink>
                        </div>
                    </div>
                    { !errorSignIn ? (
                        <>
                            <h4 className="errorMessage"
                                style={{
                                    display: 'none'
                                }}
                            >
                                Error Signing In. Try Again.
                            </h4>
                        </>
                    ) : (
                        <h4 className="errorMessage"
                            style={{
                                borderRadius: 5,
                                backgroundColor: '#FF6B6B',
                                color: 'white',
                                width: '20%',
                                minWidth: 150,
                                position: 'relative',
                                left: '40%',
                                padding: 5,
                                fontWeight: 400,
                                textAlign: 'center',
                            }}
                        >
                            Error Signing In. Try Again.
                        </h4>
                    )}
                    <div
                        className='infoSection'
                        style={{
                            width: "102%",
                            height: 250,
                            position: 'relative',
                            top: 250,
                            left: "-1%",
                            margin: 0,
                            color: 'white',
                            backgroundColor: '#1F1F1F'
                        }}
                    >
                        <BottomLinks />
                    </div>
                    <Outlet />
                </>
            ) : (
                <>
                    { !secRe ? (
                        <Navigate to="/products" />
                    ) : (
                        <Navigate to="/customerPortal" />
                    )}
                </>
            )}
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
        </div>
    )
}
