import React, { useState, useEffect } from 'react';
import './Upgrade.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Upgrade() {

    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);

    const type = params.get("type");
    const subscription_id = params.get("subscription_id");

    const upgradeFunction = async () => {
        await axios.post("https://aiserver.herokuapp.com/upgrade", {
            price_id: type,
            subscription_id: subscription_id,
        })
            .then(() => {
                navigate("/dashboard/account");
            })
            .catch((err) => {
                console.log({ message: err.message });
            })
    }

    return (
        <div className='mainUpgrade' >
            <div className="upgradeContainer" >
                <p>Are you sure you want to change your subscription?</p>
                <button id="upgradeButton" 
                    onClick={() => {
                        upgradeFunction();
                    }}
                >
                    Yes
                </button>
                <button id="upgradeButton" 
                    onClick={() => {
                        navigate("/dashboard/pricing");
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    )
}
