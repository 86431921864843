import React, { useState, useEffect } from 'react';
import BarNav from './BarComponent';
import { auth, db } from '../auth/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function CancelPage() {
  const [userEmail, setUserEmail] = useState("");
  const [sid, setSid] = useState("");
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState()
  const navigate = useNavigate();
  
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const email = user.email;
        setUserEmail(email);
        setUid(user.uid)
        
        const docRef = doc(db, "customers", user.uid);
        const rawData = await getDoc(docRef);

        const data = rawData.data();

        setSid(data.subscriptionId);
      } else {
        console.log("Not Signed In - Please try again");
      };
    })
  }, [])

  const cancelFunction = async () => {
    setLoading(true);
    axios.post("https://aiserver.herokuapp.com/cancel", {
      subscription_id: sid
    })
      .then(async () => {
        const userRef = doc(db, 'customers', uid)
        const userData = await updateDoc(userRef, {
          userSubscription: false
        })
      })
      .then(() => {
        signOut(auth);
        setLoading(false);
        navigate("/confirm");
      })
      .catch((err) => {
        console.log({ message: err.message });
        setLoading(false);
      })
  }
  
  return (
    <div className='main'>
        <Helmet>
            <html lang="en" />
            <title>Manage</title>
            <meta name="description" content="Manage your account with Array Assistant." />
        </Helmet>
        <BarNav />
        <div
          style={{
            position: 'relative',
            top: 110,
            marginLeft: 35,
            fontFamily: 'Helvetica, Arial, sans-serif',
            height: 350,
            width: '30%',
            borderRadius: 8,
            border: '1px solid black',
            padding: 10,
            paddingLeft: 10
          }}
        >
          <h2
            style={{
              marginBottom: 25,
              position: 'relative',
              top: -15,
              width: '97%',
              borderBottom: '1px solid black',
              paddingLeft: 10
            }}
          >
            Cancel Subscription:
          </h2>
          <div id="contentCancel"
            style={{
              paddingLeft: 10,
            }}
          >
            <h3
              style={{
                marginTop: -30,
              }}
            >
              Account: { !auth.currentUser ? "Not Signed In" : userEmail}
            </h3>
            <p
              style={{
                fontFamily: "Inter, sans-serif",
                fontWeight: 300,
                fontSize: 17,
                color: 'black',
              }}
            >
              Upon clicking below, you will be logged out. <span style={{fontWeight: 500}} >You will no longer have access to your paid account, we suggest cancelling at the end of the month to get the full use out of your subscription.</span>
              <br/> <br/> You will be taken to the home page or the sign-in. This means that your account has been cancelled.
            </p>
            <button className="buttonInSign" 
              onClick={() => {
                cancelFunction();
              }}
            >
              { loading ? "Loading..." : "Cancel Subscription" }
            </button>
          </div>
          <p
            style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 300,
              fontSize: 15,
              color: 'grey',
            }}
          >
            Note: To have your account fully deleted, please contact us at <span style={{color: 'black' }}>contact@arrayassistant.ai</span>.
          </p>
        </div>
    </div>
  )
}
