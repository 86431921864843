import React from 'react'
import { Helmet } from 'react-helmet'
import BottomLinks from './BottomLinks';
import BarNav from './BarComponent';

export default function ClimateInformation() {
  return (
        <div className='ClimateMain' >
            <Helmet>
                <html lang="en" />
                <title>Climate Information</title>
                <meta name="description" content="Learn more about our commitment to Climate Activism." />
                <meta name="keywords" content="climate commitment, ai for climate awareness, climate change" />
            </Helmet>
            <div className='climateContent' 
                style={{
                    marginTop: 150,
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 300,
                    fontSize: 20,
                    width: '70%',
                    marginLeft: '15%',
                }}
            >
              <div
                  style={{
                      padding: 5,
                  }}
              >
                  <p
                      style={{
                          width: '40%',
                          fontFamily: 'Inter Tight, sans-serif',
                          fontSize: 24,
                          fontWeight: 500,
                          borderBottom: '1px solid black'
                      }}
                  >
                    Climate Commitment
                  </p>
                  <p>
                    At Array Assistant, we understand the severity of the current climate crisis. That is why we are committed to being a part of the change.To help further
                    the cause, we donate 1% of our current gross revenue to charities fighting to stop climate change. However, that's not all!
                  </p>
                  <p>
                    In addition to donating, we also strive to be a carbon nuetral company. This means that our operations produce a net carbon footprint of zero! Currently, the 
                    operation of our services meets all standards of a net nuetral compnay. However, this doesn't just mean we outsource our carbon emission. In addition to our own operations, 
                    all of the companies that we work with are also net neutral companies! 
                  </p>
                  <p>
                    In the future, we will continue this climate activism to the best of our abilities and strive to continue making planet focused decisions. On top of keeping 
                    our business and its operations net nuetral, we aim to go further. Our future goals are to become a company with a negative footprint. To do this, we aim to continue 
                    donating to organizations that can help return the planet to its original state while developing our own technology focused on minimizing the carbon output of both our 
                    business and our customers.
                  </p>
                  <p>
                    The ArrayAssistant Team
                  </p>
              </div>
            <link href="https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight:wght@500&display=swap" rel="stylesheet"/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin></link>
        </div>
        <div
            className='infoSection'
            style={{
                width: '102%',
                height: 250,
                position: 'absolute',
                top: '105vh',
                left: '-1%',
                color: 'white',
                backgroundColor: '#1F1F1F' 
            }}
        >
            <BottomLinks />
        </div>
        <BarNav />
      </div>
  )
}
